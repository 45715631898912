export function AccUsernameValidator(e) {
  var code = "charCode" in e ? e.charCode : e.keyCode;
  if (
    !(code === 45) && // -
    !(code === 95) && // _
    !(code > 47 && code < 58) && // numeric (0-9)
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123) // lower alpha (a-z)
  ) {
    e.preventDefault();
  }
}
export function AccFLnameValidator(e) {
  var code = "charCode" in e ? e.charCode : e.keyCode;
  if (
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123) // lower alpha (a-z)
  ) {
    e.preventDefault();
  }
}
export function NumberValidator(e) {
  var code = "charCode" in e ? e.charCode : e.keyCode;
  if (
    !(code > 47 && code < 58) // numeric (0-9)
  ) {
    e.preventDefault();
  }
}
export function DecimalValidator(e) {
  var code = "charCode" in e ? e.charCode : e.keyCode;
  if (
    !(code > 47 && code < 58) &&
    code !== 46 // numeric (0-9)
  ) {
    e.preventDefault();
  }
}
export function NameValidator(e) {
  var code = "charCode" in e ? e.charCode : e.keyCode;
  if (
    !(code === 32) && //
    !(code === 45) && // -
    !(code === 95) && // _
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123) && // lower alpha (a-z)
    !(code > 47 && code < 58) // numeric (0-9)
  ) {
    e.preventDefault();
  }
}

export function SearchValidator(e) {
  var code = "charCode" in e ? e.charCode : e.keyCode;
  if (
    !(code === 32) && //
    !(code === 45) && // -
    !(code === 95) && // _
    !(code === 47) && // /
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123) && // lower alpha (a-z)
    !(code > 47 && code < 58) // numeric (0-9)
  ) {
    e.preventDefault();
  }
}
export function ColNameValidator(e) {
  var code = "charCode" in e ? e.charCode : e.keyCode;
  if (
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123) && // lower alpha (a-z)
    !(code > 47 && code < 58) && // numeric (0-9)
    !(code === 95) // _
  ) {
    e.preventDefault();
  }
}

export function SchemaValidator(e) {
  var code = "charCode" in e ? e.charCode : e.keyCode;
  if (
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123) && // lower alpha (a-z)
    !(code > 47 && code < 58) && // numeric (0-9)
    !(code === 95) && // _
    !(code === 219) && // {
    !(code === 221) // }
  ) {
    e.preventDefault();
  }
}
export function CalculateSchemaTextValidator(e) {
  var code = "charCode" in e ? e.charCode : e.keyCode;
  if (
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123) && // lower alpha (a-z)
    !(code > 47 && code < 58) && // numeric (0-9)
    !(code === 45) && // -
    !(code === 95) &&  // _
    !(code === 32)  // space
  ) {
    e.preventDefault();
  }
}
export function CalculateSchemaIntValidator(e) {
  var code = "charCode" in e ? e.charCode : e.keyCode;
  if (
    !(code > 47 && code < 58) && // numeric (0-9)
    !(code === 43) && // +
    !(code === 45) && // -
    !(code === 42) && // *
    !(code === 47) && // /
    !(code === 46) // .

  ) {
    e.preventDefault();
  }
}
export function MaxChar(e, max) {
  return (e.target.value = e.target.value.toString().slice(0, max));
}

export function EmailValidator(e) {
  let value = e.target.value;
  let pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  if (!pattern.test(value)) {
    return false;
  }
  return true;
}

export function LocationValidaor(e) {
  var code = "charCode" in e ? e.charCode : e.keyCode;
  if (!(code > 44 && code < 59)) {
    e.preventDefault();
  }
  let value = e.target.value;
  let v = value.split(":");
  let lat = parseFloat(v[0]);
  let lng = undefined;
  if (v.length == 2) lng = parseFloat(v[1]);
  if (lat < -90 || lat > 90) {
    e.preventDefault();
    return false;
  }
  if (lng < -180 || lng > 180) {
    e.preventDefault();
    return false;
  }
  const pattern = new RegExp(
    /^((\-?|\+?)?\d+(\.\d+)?):\s*((\-?|\+?)?\d+(\.\d+)?)$/gi
  );
  if (!pattern.test(value)) {
    return false;
  }
  return true;
}

export function ValueValidator(e, min, max) {
  var val = e.target.value;
  var mi = parseInt(min, 10);
  var ma = parseInt(max, 10);
  if (val > ma) {
    return (e.target.value = ma);
  }
  if (val < mi) {
    return (e.target.value = mi);
  }
  return e.target.value;
}

export function FormOptValidator(e) {
  var code = "charCode" in e ? e.charCode : e.keyCode;
  if (
    !(code === 32) && //
    !(code === 45) && // -
    !(code === 46) && // .
    !(code === 47) && // _
    !(code === 95) && // _
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123) && // lower alpha (a-z)
    !(code > 47 && code < 58) && // numeric (0-9)
    !(code === 40) && // (
    !(code === 41) && // )
    !(code === 43) // )
  ) {
    e.preventDefault();
  }
}

export function MaxValue(e, min, max) {
  var val = e.target.value;
  var mi = parseInt(min, 10);
  var ma = parseInt(max, 10);
  if (val == "") return "";
  if (val > ma) {
    return (e.target.value = ma);
  }
  // if (val < mi) {
  //   return (e.target.value = mi);
  // }
  return e.target.value;
}

export function GetValidator(e, q_type) {
  if (
    q_type === "ele_number" ||
    q_type === "ele_counter" ||
    q_type === "ele_phone" ||
    q_type === "ele_rating"
  ) {
    return NumberValidator(e);
  } else if (
    q_type === "ele_decimal" ||
    q_type === "ele_len" ||
    q_type === "ele_area"
  ) {
    return DecimalValidator(e);
  } else if (q_type === "ele_email") {
    return EmailValidator(e);
  } else if (q_type === "ele_location") {
    return LocationValidaor(e);
  }
  return true;
}

export function GetAttributeValidator(e, type) {
  if (type.toLowerCase().includes("int")) {
    return NumberValidator(e);
  } else if (type.toLowerCase().includes("real")) {
    return DecimalValidator(e);
  }
}

export function GetAttributeMaxValueValidator(e, type) {
  if (type.toLowerCase().includes("int")) {
    return MaxValue(e, 0, 99999999);
  } else if (type.toLowerCase().includes("real")) {
    return MaxValue(e, 0, 99999999);
  }
  return MaxChar(e, 50);
}

export function GetMaxValueValidator(e, q_type, min, max, max_char = null) {
  if (
    q_type === "ele_number" ||
    q_type === "ele_counter" ||
    q_type === "ele_phone" ||
    q_type === "ele_rating" ||
    q_type === "ele_decimal" ||
    q_type === "ele_len" ||
    q_type === "ele_area"
  ) {
    if (!e.target.value) return e.target.value;

    return MaxValue(e, min, max);
  } else if (q_type === "ele_location") {
    return;
  }
  if (max_char) return MaxChar(e, max_char);
  return MaxChar(e, max);
}
