import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { AiOutlineMore } from "react-icons/ai";

const FloatingMenu = ({ opt, left = false }) => {
  // const [value, setValue] = useState(def ? def : opt[0]);
  // useNonInitialEffect(() => {
  //   handler(value.value);

  //   return () => {};
  // }, [value]);

  return (
    <Menu as="div" className="align-right relative">
      <Menu.Button className="p-0 w-[12px]">
        <AiOutlineMore
          title="Settings"
          className="cursor-pointer text-slate-700 p-0 my-auto text-lg me-0"
        />
      </Menu.Button>
      <div className="ml-4 -mt-4 z-1000">
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`
            w-40 mt-2 z-100 absolute  right-0 top-4 origin-top-left bg-white divide-y divide-gray-100 shadow-lg ring-2 ring-black ring-opacity-5 focus:outline-none ${left && "right-0"
              }`}
          >
            <div className="px-1 py-1 ">
              {opt?.map((o, idx) => {
                return (
                  <Menu.Item key={idx}>
                    {({ active }) => (
                      <button
                        className={`${active &&
                          (o?.hover
                            ? o?.hover
                            : "bg-yellow-100 text-yellow-900 opacity-80")
                          } group flex items-center w-full px-2 py-2 text-sm`}
                        onClick={() => {
                          o?.handler();
                          // {
                          //   p.owner
                          //     ? handleChange("leave", p)
                          //     : handleChange("remove", p);
                          // }
                        }}
                      >
                        {o.label}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </div>
    </Menu>
  );
};

export default FloatingMenu;
