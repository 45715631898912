import axios from "api/axios";
import Download from "components/responses/Download";
import MemoTable from "components/responses/Table";
import { URL_DATA_DETAILS, URL_NOTIFICATION } from "constants";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBase from "hooks/useBase";
import "ol/ol.css";
import { createContext, useEffect, useReducer, useRef } from "react";
import { toast } from "react-toastify";
import useSWR from "swr";

function sortByPosition(array) {
  return array.sort((a, b) => a.position - b.position);
}

function reducer(state, action) {
  switch (action.type) {
    case "fetch":
      let formList = [{ label: "All", value: "all" }];
      let quesList = {}
      let fl = []
      let ffl = []
      let formIdName = {}
      action.data?.forms.forEach((f) => {
        formList.push({ label: f.name, value: f.id })
        formIdName[f.id] = f.name
        ffl.push({ label: f.name, value: f.id })
        let pl = [];
        let qlComp = [];
        f?.page_list?.forEach((p) => {
          let ql = [];
          p.ques_list.forEach((q) => {
            ql.push({ ...q, opt_list: sortByPosition(q.opt_list) });
            if (q.q_type === "ele_text" || q.q_type === "ele_number" || q.q_type === "ele_dropdown" || q.q_type === "ele_single_choice")
              qlComp.push({ label: q.question, value: q.id })
          });
          pl.push({ ...p, ques_list: sortByPosition(ql) });
        });
        quesList[f.id] = qlComp;
        fl.push({ ...f, page_list: sortByPosition(pl) });
      });
      let sl = [{ label: "All", value: "all" }]
      action.data.shpList.forEach(s => {
        sl.push({ label: s.name, value: s.id, form: s.form });
      })
      let teamList = []
      let teamIdName = {}
      action.data.team.forEach(t => {
        teamList.push({ label: `${t.member__first_name} ${t.member__last_name} (${t.member__phone_number})`, value: t.member__id });
        teamIdName[t.member__id] = t.member__username
      })
      return { ...action.data, shpCount: action.data.shpList.length, quesList: quesList, formList: formList, shpList: sl, forms: fl, filterFormList: ffl, teamList: teamList, teamIdNameMap: teamIdName, formIdNameMap: formIdName }
    case "removeDownloadUrls":
      return {
        ...state,
        attrUrl: null,
        imgUrl: null,
        compDate: null,
      };
    case "compileData":
      return {
        ...state,
        attrUrl: action.data.attr_url,
        imgUrl: action.data.img_url,
        compDate: action.data.date,
      };
    default:
      return state;
  }
}
export const ResponsesContext = createContext();


const fetcher = async (access) => {
  const response = await axios.get(URL_NOTIFICATION, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `JWT ${access}`,
    },
    params: { page: "data" }
  });
  return response?.data?.data;
};

const Responses = () => {
  const [state, dispatch] = useReducer(reducer, {
    forms: [],
    rules: [],
    shpList: [],
    attrUrl: null,
    imgUrl: null,
    date: null,
    quesList: [],
    formList: [],
    formIdNameMap: {},
    filterFormList: [],
    teamList: [],
    teamIdNameMap: {},
    shpCount: 0,
    surArea: {}
  });
  const axiosPrivate = useAxiosPrivate()
  const { proj } = useBase()
  const refModal = useRef(null);

  let showDownloadCard = false;
  if (state.imgUrl || state.attrUrl) showDownloadCard = true;
  const { auth } = useAuth();
  const { data, error, isLoading } = useSWR("notification", () => fetcher(auth?.access));
  const fetch = async () => {
    await axiosPrivate
      .get(`${URL_DATA_DETAILS}${proj?.id}`)
      .then((res) => {
        dispatch({ type: "fetch", data: res.data.data })
      })
      .catch((error) => { });
  };
  useEffect(() => {
    if (data?.msg?.page === "data") {
      let attr = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
      if (data?.msg?.message_type == "info")
        toast.info(data?.msg?.message, attr)
      else if (data?.msg?.message_type == "success") {
        fetch()
        toast.success(data?.msg?.message, attr)
      }
      else if (data?.msg?.message_type == "error")
        toast.error(data?.msg?.message, attr)
    }
  }, [data])
  useEffect(() => {
    fetch()
    document.title = "Geodit | Data";
  }, []);
  return (
    <ResponsesContext.Provider
      value={{
        state,
        dispatch,
        proj
      }}
    >
      <div className="h-full flex flex-col">
        {proj?.access_level < 3 &&
          (
            <div className="grid grid-cols-4 gap-4 justify-between align-middle text-sm boxes mt-0">

              <div className="">
                <button
                  onClick={() => {
                    refModal.current.open();
                  }}
                  className="button bg-yellow-400 text-yellow-900 w-full"
                >
                  Generate Download Link
                </button>
              </div>
              {showDownloadCard && (<>
                <div className="col-span-2 justify-self-center text-center">
                  <strong>Your download link is ready</strong>
                  {state.date && (
                    <h6 className="mb-0 text-xxs">
                      Last Compiled - {state.date}
                    </h6>
                  )}
                </div>
                <div className="flex align-center text-xs ">
                  <div className="flex flex-col mr-2 gap-1">
                    {state.attrUrl && <span>Data Url - </span>}
                    {state.imgUrl && <span>Images Url - </span>}
                  </div>
                  <div className="flex flex-col pr-2 border-r  gap-1 text-yellow-900">
                    {state.attrUrl && (
                      <a className="underline" href={state.attrUrl}>
                        Click here to start downloading
                      </a>
                    )}
                    {state.imgUrl && (
                      <a className="underline" href={state.imgUrl}>
                        Click here to start downloading
                      </a>
                    )}
                  </div>
                  <span className="ml-4 text-xxs my-auto">Valid for 48 hours</span>
                </div>
              </>)}

            </div>
          )}

        <MemoTable />
        <Download ref={refModal} />

      </div>

    </ResponsesContext.Provider >
  );
};

export default Responses;
