import Header from "components/layout/header/Header";
// import { LayoutContext } from "components/layout/Layout";
import axios from "api/axios";
import FloatingMenu from "components/common//FloatingMenu";
import Modal from "components/common//Modal";
import { MaxChar, NameValidator } from "components/common//Validators";
import { URL_PROJECTS, URL_PROJECT_CREATE, URL_PROJECT_LEAVE, URL_PROJECT_REMOVE } from "constants";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useModalDelete } from "hooks/useModalDelete";
import usePlan from "hooks/usePlan";
import { useEffect, useReducer, useRef, useState } from "react";
import Puff from "react-loading-icons/dist/esm/components/puff";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import useSWR from "swr";

function reducerProject(state, action) {
  switch (action.type) {
    case "fetchProj":
      if (action.data) {
        return { proj: action.data };
      }
      return { proj: [] };
    case "deleteProj":
      return {
        proj: state.proj.filter(
          (item) => item.id.toString() !== action.id.toString()
        ),
      };
    default:
      return { proj: state.proj };
  }
}

const fetcher = async (access) => {
  const response = await axios.get(URL_PROJECTS, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `JWT ${access}`,
    },
  });
  return response?.data?.data;
};

function Dashboard() {
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { plan } = usePlan();
  const navigate = useNavigate();
  const refModal = useRef(null);
  const loadingRef = useRef();

  const [fieldError, setFieldError] = useState({ field: null, error: null });
  const [state, dispatch] = useReducer(reducerProject, {
    proj: [],
  });
  const { data, error, isLoading } = useSWR("dashboard", () => fetcher(auth?.access));

  let is_expired = true;
  if (plan?.days_left > 0 && !plan?.is_expired) is_expired = false;

  const showLoading = () => {
    loadingRef?.current.classList.remove("hidden");
  };
  const hideLoading = () => {
    loadingRef?.current.classList.add("hidden");
  };

  useEffect(() => {
    document.title = "Geodit | Dashboard";
  }, []);

  useEffect(() => {
    dispatch({ type: "fetchProj", data: data });
  }, [data]);

  async function handleDel(e, id) {
    e.preventDefault();
    closeDelModal();
    await axiosPrivate
      .post(
        `${URL_PROJECT_REMOVE}/${id}`
      )
      .then((res) => {
        dispatch({ type: "deleteProj", id: id });
      })
      .catch((error) => {
        toast.error("Something went wrong. Please contact help desk", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  const [DelModal, openDelModal, closeDelModal] = useModalDelete(
    "Delete Project",
    handleDel
  );

  const handleChange = async (value, p) => {
    if (value === "leave") {
      await axiosPrivate
        .post(
          `${URL_PROJECT_LEAVE}/${p.id}`,
        )
        .then((res) => {
          dispatch({ type: "deleteProj", id: p.id });
        })
        .catch((error) => {
          toast.error("Something went wrong. Please contact help desk", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else if (value === "remove") {
      openDelModal(p.id);
    }
  };

  const handleModalSubmit = async (event, buttonRef) => {
    event.preventDefault();
    let name = event.target.name.value;
    if (!name || name === "") {
      setFieldError({
        field: "name",
        error: "Field cannot be empty",
      });
    } else {
      await axiosPrivate
        .post(
          `${URL_PROJECT_CREATE}`,
          {
            name: event.target.name.value,
            task: "createProj",
          }
        )
        .then((res) => {
          const result = res.data;
          refModal.current.close();
          // setCookies("proj", result.data);
          navigate(`/${result.data.slug}/overview`, { state: result.data });
        })
        .catch((error) => {
          const res = error.response.data;
          let msg = "Something went wrong";
          try {
            msg = res.message;
          } catch (error) { }
          toast.error(msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
    if (buttonRef.current) buttonRef.current.disabled = false;
  };
  if (error) return <span>Error ...</span>;
  if (isLoading) return <span>Loading ...</span>;
  return (
    <>
      <Header miniSidebar={false} showLoading={showLoading} hideLoading={hideLoading} />
      <div className="p-4">
        <div
          ref={loadingRef}
          className="z-[100000000] backdrop-blur-sm fixed top-0 left-0 w-full h-full grid place-items-center hidden bg-black opacity-40"
        >
          <div className="text-center opacity-100">
            <Puff className="mx-auto mb-2" stroke="#ffffff" color="#4fa94d" />
            <span className="text-white"> Processing ...</span>
          </div>
        </div>
        <div className="2xl:w-2/4 lg:w-3/5 md:w-4/6 w-3/5 mx-auto my-20 mb-0">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:gap-12 gap-8">
            <div
              className="card flex flex-col justify-center"
              onClick={() => {
                if (is_expired)
                  toast.error("Your plan has expired. Please recharge", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: "expired",
                  });
                else {
                  refModal.current.open();
                }
              }}
            >
              <h2 className="mx-auto text-2xl font-medium">+</h2>
              <h6 className="mx-auto text-md">Add New Project</h6>
            </div>
            {state?.proj?.map((p) => {
              return (
                <div
                  className={`card ${p.is_expired && "bg-gray-100"}`}
                  key={p.id}
                >
                  <div className="flex justify-between border-b text-sm p-2 pl-4 shadow-sm">
                    <span>{p.name}</span>
                    {p.access_level > 1 ? (
                      <FloatingMenu
                        opt={[
                          {
                            label: "Leave Project",
                            handler: () => handleChange("leave", p),
                          },
                        ]}
                      />
                    ) : (
                      <FloatingMenu
                        opt={[
                          {
                            label: "Delete Project",
                            handler: () => handleChange("remove", p),
                          },
                        ]}
                      />
                    )}
                  </div>
                  <div
                    className="w-full h-full"
                    onClick={() => {
                      // if (p.is_expired)
                      //   toast.error("Your plan has expired. Please recharge", {
                      //     position: "top-center",
                      //     autoClose: 5000,
                      //     hideProgressBar: false,
                      //     closeOnClick: true,
                      //     pauseOnHover: true,
                      //     draggable: true,
                      //     progress: undefined,
                      //     toastId: "expired",
                      //   });
                      // else {
                      navigate(`/${p.slug}/overview`, { state: p });
                      // }
                    }}
                  >
                    <div className="px-4 py-2">
                      <span className="inline-block text-sm border-b-2 border-slate-700">
                        {p.slug}
                      </span>
                      <span
                        className={`inline-block text-xs ${p.is_active ? "text-teal-500" : "text-red-500"
                          } float-right`}
                      >
                        {p.is_active ? "Live" : "Draft"}
                      </span>
                      <br />
                      <span className="inline-block text-xxs mt-1">
                        {p.created_on}
                      </span>
                      <br />

                      <span className="text-xxs">{p.role}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Modal
          ref={refModal}
          heading={"Add new project"}
          fields={
            <div className="flex">
              <span className="input-text">Project Name</span>
              <div className="flex-grow relative">
                <input
                  className="input mb-1"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter name here"
                  required
                  onInput={(e) => {
                    MaxChar(e, 50);
                    if (fieldError.field === "name") setFieldError({});
                  }}
                  onKeyPress={NameValidator}
                />
                {fieldError.field === "name" && (
                  <div
                    className="invalid-tooltip"
                    id={`error-${fieldError.field}`}
                  >
                    {fieldError.error}
                  </div>
                )}
              </div>
            </div>
          }
          handleSubmit={handleModalSubmit}
        />
        {DelModal}
      </div>
    </>
  );
}

export default Dashboard;
