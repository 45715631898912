import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from 'context/AuthProvider';
import { PlanProvider } from "context/PlanProvider";


// ReactDOM.render(<App />, document.getElementById("root"));

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <CookiesProvider>
    <BrowserRouter>
      <AuthProvider>
        <PlanProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </PlanProvider>
      </AuthProvider>
    </BrowserRouter>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
