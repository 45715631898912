import { ResponsesContext } from "pages/Responses";
import { useContext, useState } from "react";

const TableRow = ({ ans, uniqueQues, removeAns, refAns, user }) => {
  const ctx = useContext(ResponsesContext);
  const state = ctx?.state
  const forms = state?.forms
  const rules = state?.rules
  const teamIdName = state?.teamIdNameMap
  const formIdName = state?.formIdNameMap
  const [status, setStatus] = useState(ans?.status);
  let statusText = status
  if (status == "-" || status == "") statusText = "Unchecked"

  function showAns(remAns) {
    if (ans.form_id) {
      let form = null;
      forms.forEach((f) => {
        if (f.id === ans.form_id) form = f;
      });
      let ruleList = [];
      rules.forEach((r) => {
        if (r.form_id === form.id) {
          ruleList.push(r);
        }
      });
      if (form)
        refAns.current.showAns(
          form,
          ruleList,
          ans.id,
          remAns,
          null,
          null,
          setStatus
        );
    }
  }

  let color = "";
  if (status === "Incorrect") {
    color = "text-red-600";
  } else if (status === "Approved") {
    color = "text-green-600";
  }
  return (
    <tr v-for="row in rows" className={`align-middle table-row`}>
      <td className="table-data">{ans.id}</td>
      {uniqueQues && uniqueQues?.title &&
      <td className="table-data">{ans.unique_id}</td>
      }<td className="table-data">{teamIdName[ans.editor_id]}</td>
      <td className="table-data">{formIdName[ans.form_id]}</td>
      <td className="table-data">{ans.edited_on}</td>
      <td className="table-data">{ans.verified_on?ans.verified_on:"-"}</td>
      <td className="table-data">{teamIdName[ans.verified_by_id] ? teamIdName[ans.verified_by_id] : status === "Incorrect" || status === "Approved" ? user?.username : "-"}</td>
      <td className={`table-data font-bold ${color}`}>
        {statusText}
      </td>
      <td className="table-data">
        <button
          type=""
          onClick={() => showAns(removeAns)}
          className="button bg-slate-700 text-white p-1 h-[28px] w-[68px]"
        >
          Show
        </button>
      </td>
    </tr>
  );
};

export default TableRow;
