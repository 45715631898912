import { Tab } from "@headlessui/react";
import Modal from "components/common//Modal";
import { URL_ANS_REMOVE_PAGE, URL_ANS_STATUS } from "constants";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState
} from "react";

import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { URL_ANS_DELETE, URL_ANS_EDIT, URL_ANS_SHOW } from "constants";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBase from "hooks/useBase";
import { AiFillCheckCircle, AiFillCloseCircle, AiFillDelete, AiFillMinusCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import Ans from "./ans/Ans";
import { UniqueIdGenerator } from "./common/UniqueIdGenerator";
import Save from "./forms/Save";


function getExtension(filename) {
  return filename.substring(filename.lastIndexOf(".") + 1);
}


function reducer(state, action) {
  switch (action.type) {
    case "showAns":
      return { ...state, ...action.data };
    case "reset":
      return { ...action.data };
    case "close":
      return { ...state };
    default:
      return state;
  }
}

let timeout1;

const S3_BUCKET = process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME;
const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const REGION = process.env.REACT_APP_AWS_REGION;


const AnsModal = forwardRef((props, ref) => {
  const [data, setData] = useState({});
  const [shp, setShp] = useState({});
  const [status, setStatus] = useState("");
  const [pageList, setPageList] = useState([]);
  const [forRules, setForRules] = useState([]);
  const [dependentQues, setDependentQues] = useState({});
  const refModal = useRef();
  const callbackFun = useRef();
  const saveRef = useRef()
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [state, dispatch] = useReducer(reducer, {
    form: null,
    rules: null,
    ans: {},
    attr: [],
    removeAns: null,
    feat_id: null,
    shp: null,
    geom: null,
    ans_id: null,
  });
  const axiosPrivate = useAxiosPrivate()
  const { proj, showLoading, hideLoading } = useBase()
  let is_allowed = proj?.access_level <= 3;

  useEffect(() => {
    let _data = {};
    let pgList = [];
    const pageQuesMap = {}
    const dependent_ques = {}
    state.form?.page_list?.forEach((p) => {
      p?.ques_list?.forEach((q) => {
        if (q.default_type == "calculate") {
          q.default?.match(/(?<=\{).*?(?=\})/g)?.forEach(m => {
            if (dependent_ques[m]) {
              dependent_ques[m].push([q.id, q.default])
            }
            else dependent_ques[m] = [[q.id, q.default]]
          })
        }
        pageQuesMap[q.id] = p.id
      })
    })
    if (state?.ans?.ans_data) {
      const r = state?.ans?.ans_data.reduce((r, a) => {
        r[pageQuesMap[a.ques]] = r[pageQuesMap[a.ques]] || [];
        r[pageQuesMap[a.ques]].push(a);
        return r;
      }, Object.create(null))
      Object.entries(r)?.map(([k, v]) => {
        let result = v.reduce(function (r, a) {
          r[a.page_key] = r[a.page_key] || [];
          r[a.page_key].push(a);
          return r;
        }, Object.create(null));
        _data[k] = result;
      });
    }
    state.form?.page_list?.forEach((p) => {
      if (_data.hasOwnProperty(p.id)) {
        let max_page_key = Math.max(...Object.keys(_data[p.id]));
        Object.keys(_data[p.id])?.map((k) => {
          pgList.push({ ...p, page_key: k, max_page_key: max_page_key });
        });
      } else {
        pgList.push({ ...p, page_key: 1, max_page_key: 1 });
      }
    });
    let forLoopPages = [];
    state.rules?.forEach((r) => {
      if (r.type === "for") {
        r?.then_list?.forEach((t) => {
          if (t.then_type === "page") forLoopPages.push(t.then_id);
        });
      }
    });
    setDependentQues(dependent_ques)
    setPageList(pgList);
    setForRules(forLoopPages);
    setStatus(state?.ans?.status);

    return () => {
      setPageList([])
      setForRules([])
      setSelectedIndex(0)
    };
  }, [state]);
  // console.log(state.form)
  // console.log(state.rules)
  // console.log(state.ans)

  const showAns = async (
    form,
    ruleList,
    ans_id,
    removeAns,
    shp = null,
    feat_id = null,
    callback = null
  ) => {
    let data = {
      ans_id: ans_id,
      shp: shp?.id,
      geom: shp?.g_type,
      feat_id: feat_id,
    };
    await axiosPrivate
      .get(`${URL_ANS_SHOW}/${proj?.id}`, { params: data })
      .then((res) => {
        let ai = ans_id;
        if (!ai) {
          ai = res?.data?.data?.ans?.id;
        }
        dispatch({
          type: "showAns",
          data: {
            ans: res?.data?.data?.ans,
            form: form,
            rules: ruleList,
            attr: res?.data?.data?.attr?.attr,
            removeAns: removeAns,
            feat_id: feat_id,
            shp: shp?.id,
            geom: shp?.g_type,
            ans_id: ai,
          },
        });
        let ans = {};
        res?.data?.data?.ans?.ans_data?.forEach(a => {
          ans[a.ques_key] = a.value;
        })
        setData(ans);
        setShp(shp);
        callbackFun.current = callback;
        refModal.current.open();
      })
      .catch((error) => {
      });
  }
  useImperativeHandle(ref, () => {
    return {
      showAns: showAns,
      hideAns: () => {
        setData({});
        dispatch({ type: "close", data: false });
      },
    };
  });
  // useEffect(() => {
  //   return () => {
  //     setUpdateStyle(false);
  //   };
  // }, []);

  // const updateLyrStyle = useCallback((feat, val) => {
  //   if (ctx?.tileLyr) {
  //     const so = {};
  //     ctx?.state?.shp.forEach((s) => {
  //       if (s.id !== ctx?.selectedShp?.id) {
  //         so[`shp_${s.id}`] = s;
  //       }
  //     });
  //     if (!ctx?.selectedShp?.sa)
  //       so[`sa_${ctx?.state?.surArea.id}`] = ctx?.state?.surArea;
  //     ctx?.tileLyr?.setStyle((f, r) => {
  //       if (!f.getId()) f.setId(f.get("id"));
  //       if (f.getId() == feat) {
  //         f.set("symbology", val);
  //       }
  //       let select = false;

  //       Object.values(ctx?.selectedFeatRef?.current)?.forEach((v) => {
  //         if (v?.includes(f.getId())) {
  //           select = true;
  //         }
  //       });
  //       return LayerStyle(f, r, so[f.get("layer")], select);
  //     });
  //   }
  // }, []);
  function handleMark(ans_id, value) {
    saveRef.current.saving()

    if (timeout1) {
      clearTimeout(timeout1);
    }
    timeout1 = setTimeout(() => {
      axiosPrivate
        .post(`${URL_ANS_STATUS}/${proj?.id}`, {
          id: ans_id, status: value, feat_id: state?.feat_id,
          geom: state?.geom
        })
        .then((res) => {
          saveRef.current.saved()
        })
        .catch((error) => {
          saveRef.current.error()
        });
    }, 300);
    setStatus(value);
    callbackFun.current(value);
  }

  async function handleImg(q, qkey, pKey, img) {
    try {
      if (img) {
        const filename = `${UniqueIdGenerator()}.${getExtension(img.name)}`
        let filePath = `data/${proj?.slug}/images/${filename}`;
        // let percent = 0;
        const target = {
          Bucket: S3_BUCKET,
          Key: filePath,
          Body: img,
          ContentType: img.type,
        };
        const config = {
          bucketName: S3_BUCKET,
          region: REGION,
          credentials: {
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
          },
        };
        const parallelUploads3 = new Upload({
          client: new S3Client(config),
          params: target,
          leavePartsOnError: false, // optional manually handle dropped parts
        });
        parallelUploads3.on("httpUploadProgress", (prg) => {
          showLoading()
          // setProgressText(`Uploading ${s} ...`);
          // percent = Math.round((prg.loaded / prg.total) * 100);
          // setProgressPer(`${percent}%`);
        });
        let fullPath = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${filePath}`
        await parallelUploads3.done();
        hideLoading()
        setData((prev) => ({
          ...prev,
          [qkey]: fullPath,
        }));

        await axiosPrivate
          .post(`${URL_ANS_EDIT}/${proj?.id}`, {
            value: fullPath,
            ques_id: q.id,
            ques_key: `${qkey}`,
            page_key: pKey,
            ans_id: state?.ans_id,
            feat_id: state?.feat_id,
            geom: state?.geom,
          })
          .then((res) => {
            if (
              callbackFun.current &&
              state?.feat_id
              // && shp?.symbology_field_type == "form" && shp?.symbology_field == q.id
            ) {
              callbackFun.current({
                feat: state?.feat_id,
                ans_id: state?.ans_id,
                ques: q.id,
                quesKey: qkey,
                pageKey: pKey,
                val: fullPath,
              });
            }
          })
          .catch((error) => {
            let err = "Something went wrong. Data might not be saved.";
            if (error?.response?.data) {
              err = error.response.data;
            }
            toast.error(err, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });

      }

    } catch (e) {
      console.log(e);
    }
  }
  async function handleDefault(q, qkey, pkey, val, def) {
    if (val !== def) {
      let data = {
        value: val,
        ques_id: q.id,
        ques_key: qkey,
        page_key: pkey,
        ans_id: state?.ans?.id,
        feat_id: state?.feat_id,
        geom: state?.geom,
      }
      if (state?.feat_id) {
        await axiosPrivate
          .post(`${URL_ANS_EDIT}/${proj?.id}`, data)
          .then((res) => {
            if (
              callbackFun.current
              // && shp?.symbology_field_type == "form" && shp?.symbology_field == q.id
            ) {
              callbackFun.current({
                feat: state?.feat_id,
                ans_id: state?.ans_id,
                ques: q.id,
                quesKey: qkey,
                pageKey: pkey,
                val: val,
              });
            }
          })
          .catch((error) => {
            let err = "Something went wrong. Data might not be saved.";
            if (error?.response?.data) {
              err = error.response.data;
            }
            toast.error(err, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });

        setData((prev) => ({
          ...prev,
          [qkey]: val,
        }));
      }
      else {
        let data = {
          value: val,
          ques_id: q.id,
          ques_key: qkey,
          page_key: pkey,
          ans_id: state?.ans?.id,
        }
        await axiosPrivate
          .post(`${URL_ANS_EDIT}/${proj?.id}`, data)
          .then((res) => {

          })
          .catch((error) => {
            let err = "Something went wrong. Data might not be saved.";
            if (error?.response?.data) {
              err = error.response.data;
            }
            toast.error(err, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
        setData((prev) => ({
          ...prev,
          [qkey]: val,
        }));
      }
    }
  }
  async function removePage(pid, pKey, ans_id) {
    saveRef.current.saving()
    setPageList((prev) => {
      setSelectedIndex(prev.length - 2)
      return prev.filter((p) => {
        return p.id !== pid || p.page_key !== pKey;
      });
    });
    await axiosPrivate
      .post(`${URL_ANS_REMOVE_PAGE}/${proj?.id}`, { page_id: pid, page_key: pKey, ans_id: ans_id })
      .then((res) => {
        saveRef.current.saved()
      })
      .catch((error) => {
        saveRef.current.error()
      });
  }
  const deleteAns = async (ans_id) => {
    refModal?.current?.close();
    await axiosPrivate
      .post(`${URL_ANS_DELETE}/${proj?.id}`, {
        id: ans_id, feat_id: state?.feat_id,
        geom: state?.geom
      })
      .then((res) => {
        state?.removeAns();
      })
      .catch((error) => {
      });
  }

  function addPage(page) {
    setPageList((prev) => {
      let pgList = [];
      let newPageKey = page?.max_page_key + 1;
      pgList.push({ ...page, page_key: newPageKey, max_page_key: newPageKey });
      prev.forEach((p) => {
        pgList.push({ ...p, max_page_key: newPageKey });
      });
      return [...pgList].sort((a, b) => a.id - b.id || a.page_key - b.page_key);
    });
  }
  let color = "text-slate-600"
  let statusText = status
  if (status === "Approved")
    color = "text-green-600"
  else if (status === "Incorrect")
    color = "text-red-600"
  if (status === "-" || status === "") statusText = "Unchecked"

  let coord = state?.ans?.coord?.coordinates
  return (
    <Modal
      ref={refModal}
      heading={data.id}
      closeFun={() => setData({})}
      fields={
        <div className="bg-white border-t-0">
          <div className="mx-auto w-full text-center text-sm mb-2 -mt-4">
            <Save ref={saveRef} prefix={state?.ans_id} />
            {coord && coord?.length == 2 && <h6 className={`text-xs`}>Coordinates: {coord[0]} {coord[1]}</h6>}
            <h6 className={`font-bold ${color}`}>{statusText}</h6>
          </div>
          <Tab.Group
            selectedIndex={selectedIndex} onChange={setSelectedIndex}
          >
            <div className="flex bg-white">
              <div className="overflow-auto border border-b-0">
                <Tab.List className="flex text-sm flex-nowrap w-100">
                  {pageList?.map((k, i) => {
                    return (
                      <Tab
                        key={`pg-tab-${k.id}-${k.page_key}`}
                        className={({ selected }) =>
                          `flex-none border-r cursor-pointer hover:shadow-xl hover:bg-opacity-90
                            transition transform duration-100 ease-out ${selected && "bg-slate-100 text-slate-900"
                          }`
                        }
                      >
                        <span
                          className="px-3 py-2 "
                          id={`#pg-tab-${k.id}`}
                          data-bs-toggle="tab"
                          data-bs-target={`target-pg-${k.id}`}
                          type="button"
                          role="tab"
                          aria-controls={k.id}
                          aria-selected="false"
                        >
                          {k.page_key > 1
                            ? `Page ${k.position}-${k.page_key}`
                            : `Page ${k.position}`}
                        </span>
                      </Tab>
                    );
                  })}
                </Tab.List>
              </div>
            </div>
            <div>
              <Tab.Panels>
                {pageList?.map((k, i) => {
                  return (
                    <Tab.Panel key={`tab-body-${k.id}-${k.page_key}`}>
                      <div
                        id={`team-tab-${k.id}-${k.page_key}`}
                        role="tabpanel"
                        aria-labelledby={`${k.id}-${k.page_key}-tab`}
                        className="border"
                      >
                        <div className="grid grid-cols-6 text-md align-middle bg-slate-50 px-2">
                          <div className="my-auto">
                            {forRules.includes(k.id) && k.page_key == 1 && proj?.access_level < 4 && (
                              <span
                                className="cursor-pointer text-sm py-2 px-3 bg-slate-200  text-slate-600 hover:bg-slate-900 hover:text-white"
                                onClick={(e) => addPage(k)}
                              >
                                Add Page
                              </span>
                            )}
                          </div>
                          <h6 className="my-4 font-medium col-span-4  text-center ">
                            {k.name}
                          </h6>
                          <div className="my-auto">
                            <div className="text-right">
                              {forRules.includes(k.id) && k.page_key > 1 && proj?.access_level < 4 && (
                                <span
                                  className="cursor-pointer text-sm py-2 px-3 bg-red-200  text-red-900 hover:bg-red-900 hover:text-white"
                                  onClick={(e) => removePage(k.id, k.page_key, state?.ans_id)}
                                >
                                  Remove Page
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="overflow-y-auto mb-8 shadow-inner p-2"
                          style={{ height: "60vh" }}
                        >
                          {k?.ques_list?.map((q) => {
                            let a = null;
                            let qKey = `${q.id}--${k.page_key}`;
                            if (state?.ans?.ans_data) {
                              if (data.hasOwnProperty(qKey) && data[qKey] !== "") {
                                a = data[qKey];
                              }
                            } else if (q.default_type === "shp") {
                              state?.attr?.forEach((att) => {
                                if (att["key"] === q.default) {
                                  a = att["value"];
                                  if (!data.hasOwnProperty(qKey)) {
                                    setData((prev) => ({
                                      ...prev,
                                      [qKey]: a,
                                    }));
                                  }
                                }
                              });
                            }
                            if (q.default_type == "calculate" && !a) {
                              let matches = q.default?.match(/(?<=\{).*?(?=\})/g);
                              let newText = q.default
                              matches?.forEach(f => {
                                newText = newText?.replace(`{${f}}`, data[`${f}--${k.page_key}`])
                              })
                              try {
                                a = eval(newText)
                              } catch {
                                a = null

                              }
                            }
                            return (
                              <Ans
                                key={qKey}
                                ques={q}
                                def={a}
                                is_allowed={is_allowed}
                                handler={(value) => {
                                  if (q.q_type === "ele_image" && proj?.access_level < 4) {
                                    handleImg(q, qKey, k.page_key, value);
                                  } else if (proj?.access_level < 4) {
                                    handleDefault(
                                      q,
                                      qKey,
                                      k.page_key,
                                      value,
                                      a
                                    );
                                    dependentQues[q.id]?.forEach(dq => {
                                      let exp = dq[1].replace(`{${q.id}}`, value)
                                      let matches = exp?.match(/(?<=\{).*?(?=\})/g);
                                      let newText = exp
                                      matches?.forEach(f => {
                                        newText = newText?.replace(`{${f}}`, data[`${f}--${k.page_key}`])
                                      })
                                      let newVal = null
                                      try {
                                        newVal = eval(newText)
                                      } catch {
                                        newVal = null

                                      }
                                      if (newVal !== null)
                                        handleDefault(
                                          { id: dq[0] },
                                          `${dq[0]}--${k.page_key}`,
                                          k.page_key,
                                          newVal,
                                          "--"
                                        );
                                    })

                                  }
                                }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </Tab.Panel>
                  );
                })}
              </Tab.Panels>
              {proj?.access_level < 4 && (
                <div className="lg:flex justify-between">
                  <div className="lg:flex gap-2">
                    {status !== "Approved" && (
                      <AiFillCheckCircle className="my-auto text-4xl text-green-600 hover:cursor-pointer"
                        title="Mark as approved"
                        onClick={() => {
                          if (state?.ans_id)
                            handleMark(state?.ans_id, "Approved");
                        }}
                      />
                    )}
                    {status !== "Incorrect" && (
                      <AiFillCloseCircle className="my-auto text-4xl text-red-400 hover:cursor-pointer"
                        title="Mark as incorrect"
                        onClick={() => {
                          if (state?.ans_id)
                            handleMark(state?.ans_id, "Incorrect");
                        }}
                      />
                    )}
                    {status !== "-" && (
                      <AiFillMinusCircle className="my-auto text-4xl text-slate-400 hover:cursor-pointer"
                        title="Delete"
                        onClick={() => {
                          if (state?.ans_id)
                            handleMark(state?.ans_id, "-");
                        }}
                      />
                    )}
                  </div>
                  {proj?.access_level < 3 &&
                    <AiFillDelete className="my-auto text-4xl text-red-400 hover:cursor-pointer mt-2"
                      title="Mark as unchecked"
                      onClick={() => {
                        if (state?.ans_id)
                          deleteAns(state?.ans_id);
                      }}
                    />
                  }
                </div>
              )}
            </div>
          </Tab.Group>
        </div>
      }
      btnText={null}
      closeText={null}
      // handleSubmit={handleModalSubmit}
      width="52vw"
    />
  );
});

export default AnsModal;
