import { Fragment } from "react";

import { IoPerson } from "react-icons/io5";
import { Menu, Transition } from "@headlessui/react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import useAuth from "hooks/useAuth";

const NavUser = () => {
  const [cookies, _, removeCookie] = useCookies("user");
  const navigate = useNavigate();
  const { setAuth } = useAuth()

  function logout() {
    removeCookie("user");
    removeCookie("refresh");
    navigate("/");
    setAuth({})
  }
  return (
    <Menu as="div">
      <Menu.Button className="inline-flex justify-center">
        <IoPerson
          className="text-lg mx-2 mt-1 mr-4"
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="p-3">
            <Menu.Item>
              <div className="text-center">
                <div className="flex justify-center">
                  <IoPerson className="text-[2rem] mx-2 my-4"/>
                  {/* <img
                    style={{ height: "8rem" }}
                    src={cookies?.user?.image}
                    alt="logo"
                  /> */}
                </div>
                <span className="text-sm">
                  {cookies?.user?.first_name} {cookies?.user?.last_name}
                </span>
                <br />
                <span className="text-sm">{cookies?.user?.phone_number}</span>
              </div>
            </Menu.Item>
          </div>
          <div className="p-3  hover:bg-slate-200">
            <Menu.Item>
              <button
                type="submit"
                className="button-gray w-full"
                onClick={logout}
              >
                Logout
              </button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NavUser;
