import axios from 'api/axios';
import useAuth from './useAuth';
import { useCookies } from 'react-cookie';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const [cookie] = useCookies('refresh');
    const refresh = async () => {
        const response = await axios.post('users/refresh', { refresh: cookie?.refresh }, {
            // withCredentials: true
        });
        setAuth(prev => {
            return { ...prev, access: response.data.access }
        });
        return response.data.access;
    }
    return refresh;
};

export default useRefreshToken;