import { URL_PLAN_LIST, URL_RECHARGE_LIST } from "constants";
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Dialog, Transition } from "@headlessui/react";
import axios from "api/axios";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useCookies } from "react-cookie";
import useSWR from "swr";
import FormatDigits from "./FormatDigits";
import RechargeCard from "./RechargeCard";

const RechargeHistory = ({ data }) => {
  const openInv = () => {
    if (data.inv_loc)
      window.open(
        data.inv_loc,
        "_blank" // <- This is what makes it open in a new window.
      );
  };
  return (
    <div className="card px-3 py-2 mb-4 h-24">
      <div className="flex justify-between align-middle mb-2">
        <span className="text-slate-700 text-left">
          ₹ {FormatDigits(data.amount)}
          <h6 className="text-xxxs text-gray-400">{data.recharged_on}</h6>
        </span>
        <button
          className="button bg-slate-700 text-white text-xxs my-1 py-1"
          onClick={openInv}
        >
          Invoice
        </button>
      </div>
      <div className="border-t pt-1 text-left">
        <span className="text-xxs">{data.order_id}</span>
      </div>
    </div>
  );
};

const fetcher = async (access) => {
  const response = await axios.get(URL_RECHARGE_LIST, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `JWT ${access}`,
    },
  });
  return response?.data?.data;
};

const Recharge = ({ showLoading, hideLoading }) => {
  // const [rechargeHistory, setRechargeHistory] = useState([]);
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useAuth();
  const { data } = useSWR("recharge-list", () => fetcher(auth?.access));
  const [show, setShow] = useState(false);
  const [cookies] = useCookies(["refresh"]);
  const [selected, setSelected] = useState("plan");
  const [rechargePlans, setRechargePlans] = useState([]);
  const [rechargeHistory, setRechargeHistory] = useState([]);

  useEffect(() => {
    async function fetchRechargePlan() {
      await axiosPrivate
        .get(`${URL_PLAN_LIST}`)
        .then((res) => {
          setRechargePlans(res?.data?.data?.plans);
        })
        .catch((error) => {
          toast.error("Something went wrong. Please contact help desk", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
    fetchRechargePlan();
  }, [axiosPrivate]);

  useEffect(() => {
    setRechargeHistory(data);
  }, [data]);

  return (
    <div>
      <span
        className={`cursor-pointer mx-2 text-ms px-4 py-2 bg-slate-200  text-slate-600 hover:bg-slate-900 hover:text-white`}
        onClick={() => setShow(true)}
      >
        Recharge
      </span>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-20 top-14"
          onClose={() => setShow(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={"div"}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 opacity-70 top-12" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="border absolute top-0 right-0 inline-block h-full bg-white overflow-y-auto md:w-1/2 lg:w-1/3 xl:w-1/4 w-full">
                <Dialog.Title
                  as="h3"
                  className="p-2 text-center align-items-center flex bg-gray-100 text-md"
                >
                  <button></button>
                  <button
                    className={`flex-1 p-1 mx-6 ${selected == "plan" && "border-b-2 border-slate-600  font-bold"
                      }  cursor-pointer`}
                    onClick={() => {
                      setSelected("plan");
                    }}
                  >
                    Plans
                  </button>
                  <button
                    className={`flex-1 p-1 mx-6 ${selected == "history" && "border-b-2 border-slate-600  font-bold"
                      } cursor-pointer`}
                    onClick={() => {
                      setSelected("history")
                    }}
                  >
                    Invoice
                  </button>
                </Dialog.Title>
                <div className="my-2 py-4 px-8">
                  {selected == "history"
                    ? rechargeHistory?.map((k, i) => {
                      return <RechargeHistory key={`h-${i}`} data={k} />;
                    })
                    : rechargePlans?.map((k, i) => {
                      return (
                        <RechargeCard
                          key={`c-${i}`}
                          data={k}
                          user={cookies.user}
                          setShowMain={setShow}
                          showLoading={showLoading}
                          hideLoading={hideLoading}
                        />
                      );
                    })}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Recharge;
