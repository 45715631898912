import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

export const useModalDelete = (
  heading,
  handleSubmit,
  message = null,
  custom = null,
  customBtnText = null
) => {
  let [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(false);
  const [id, setId] = useState(null);
  function open(id) {
    setIsOpen(true);
    if (id) setId(id);
  }
  function close() {
    setIsOpen(false);
  }
  function changeHandler(e) {
    if (custom) {
      if (e.target.value === custom) {
        setState(true);
      } else {
        setState(false);
      }
    } else {
      if (e.target.value === "delete") {
        setState(true);
      } else {
        setState(false);
      }
    }
  }
  const modal = (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        onClose={() => setIsOpen(false)}
        style={{ zIndex: "1100" }}
      >
        <div className="min-h-screen px-4 text-center">
          <form
            onSubmit={(e) => {
              handleSubmit(e, id);
              close();
            }}
          >
            <Transition.Child
              as={"div"}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 opacity-70" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-teal-900 border-b pb-2"
                >
                  {heading}
                </Dialog.Title>
                <div className="my-4">
                  <div className="mb-1">
                    {message && (
                      <span className="text-sm">
                        {message}
                        <br />
                      </span>
                    )}

                    <span className="text-sm">
                      To confirm, type {custom ? custom : "delete"} in the
                      field.
                    </span>
                  </div>
                  <div className="flex">
                    <div className="flex-grow relative">
                      <input
                        className="input mb-1"
                        type="text"
                        name="delete"
                        id="delete"
                        placeholder={custom ? custom : "delete"}
                        required
                        max={6}
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 justify-end">
                  <button
                    type="submit"
                    className={`px-4 py-2 text-sm font-medium text-red-900 bg-red-200 border border-transparent ${state &&
                      "hover:bg-red-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                      }`}
                    disabled={!state}
                  >
                    {customBtnText ? customBtnText : "Delete"}
                  </button>

                  <button
                    type="button"
                    className="button bg-slate-200"
                    onClick={() => setIsOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </form>
        </div>
      </Dialog>
    </Transition>
  );
  return [modal, open, close];
};
