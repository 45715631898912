import { useNavigate } from "react-router-dom";
import { memo, useRef, useState } from "react";
import { useModalDelete } from "hooks/useModalDelete";
import FloatingMenu from "components/common//FloatingMenu";
import { Switch } from "@headlessui/react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBase from "hooks/useBase";
import { URL_FORM_UPDATE, URL_FORM_DETACH, URL_FORM_DELETE } from "constants";

let timeout1;

const FormCard = memo(({ index, data, dispatch }) => {
  const navigate = useNavigate();
  const formRef = useRef();
  const [active, setActive] = useState(data.is_active);
  const { proj } = useBase()
  const axiosPrivate = useAxiosPrivate()
  const handleDelete = async (e) => {
    e.preventDefault();
    formRef.current.classList.add("d-none");
    await axiosPrivate
      .post(`${URL_FORM_DELETE}/${proj?.id}`, { form_id: data.id })
      .then((res) => {
        dispatch({ type: "delete", id: data.id })
      })
      .catch((error) => { });
  }

  const handleDetach = async (e) => {
    e.preventDefault();
    await axiosPrivate
      .post(`${URL_FORM_DETACH}/${proj?.id}`, { 'form_id': data.id, 'shp_id': data.shp_id })
      .then((res) => {
        dispatch({ type: "detachShp", id: data.id })
      })
      .catch((error) => { });
  }

  const [DelModal, openDelModal] = useModalDelete(
    "Delete Form",
    handleDelete,
    "This will delete all the collected data of this form."
  );
  const [DetachModal, openDetachModal] = useModalDelete(
    "Detach Shapefile",
    handleDetach,
    "You cannot attach the shapefile to this form later.",
    "detach",
    "Detach"
  );
  function handleChange(value) {
    if (value === "remove") {
      openDelModal(data.id);
    } else if (value === "detach") {
      openDetachModal(data.id);
    }
  }
  let opt = [
    {
      label: "Delete",
      handler: () => handleChange("remove", data.id),
    },
  ];
  if (data.shp) {
    opt.push({
      label: "Detach Shapefile",
      handler: () => handleChange("detach", data.id),
    });
  }
  const handleUpdate = async (type, key, value) => {
    if (timeout1) {
      clearTimeout(timeout1);
    }
    timeout1 = setTimeout(() => {
      axiosPrivate
        .post(`${URL_FORM_UPDATE}/${proj?.id}`, { form_id: data.id, [key]: value })
        .then((res) => {
          dispatch({ type: type, form_id: data.id, [key]: value });
        })
        .catch((error) => { });
    }, 500);
  };

  return (
    <div
      ref={formRef}
      id={`form-${index}`}
      className="card cursor-pointer h-60 flex flex-col"
    >
      <div className={`text-sm p-2 pl-3 flex border-b shadow-md justify-end`}>
        <div className="flex-1 flex  flex-col">
          <input
            id={`name-${data.id}`}
            type="text"
            onChange={(e) => {
              handleUpdate("rename", "name", e.target.value)
            }}
            className="text-md input h-6 bg-transparent rounded-0 border-0 p-0 mb-0 focus:border focus:border-gray-400 focus:shadow-md"
            placeholder="Form Name"
            defaultValue={data.name}
            required
          />
          <div
            className="flex-1 flex  flex-col"
            onClick={() => navigate(`/${proj?.slug}/${data?.id}/forms/edit`)}
          >
            <span
              className={`text-xxs fst-italic ${active ? "text-green-600" : "text-red-600"
                }`}
            >
              {active ? "Live" : "Draft"}
            </span>
            <span className="text-xxs">{data.created_on}</span>
          </div>
        </div>

        <div className="flex flex-col justify-end gap-1">
          <div className="flex justify-end mb-1">
            <FloatingMenu opt={opt} />
          </div>
          <div className="flex-1 items-center flex">
            <Switch
              checked={active}
              onChange={() => {
                handleUpdate("active", "is_active", !active)
                setActive((prev) => !prev);
              }}
              className={`${active ? "bg-slate-700" : "bg-slate-300"}
          relative inline-flex flex-shrink-0 h-[16px] w-[32px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span
                aria-hidden="true"
                className={`${active ? "translate-x-4" : "translate-x-0"}
            pointer-events-none inline-block h-[12px] w-[12px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
          </div>
        </div>
      </div>
      <div
        onClick={() => navigate(`/${proj?.slug}/${data?.id}/forms/edit`)}
        className={`flex-1 w-100 small text-left p-3 py-2`}
      >
        <div id={`send-${index}`} className="flex flex-col">
          {data.shp && (
            <span className="text-sm fst-italic">
              Attached Shapefile - {data.shp}
            </span>
          )}
        </div>
      </div>
      {DelModal}
      {DetachModal}
    </div>
  );
});

export default FormCard;
