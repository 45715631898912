import { STATIC_URL, URL_VERIFY_RECHARGE } from "constants";
import { toast } from "react-toastify";

function Checkout(axiosPrivate, user, data, plan_id) {
  const name = `${data?.notes?.first_name} ${data?.notes?.last_name}`;
  var options = {
    key: data.key,
    order_id: data.id,
    amount: data.amount,
    name: name,
    description: data.notes.receipt,
    image: STATIC_URL + "images/geodit.png",
    handler: async function (response) {
      await axiosPrivate
        .post(
          `${URL_VERIFY_RECHARGE}`,
          {
            verifySig: true,
            ...response,
            data: data,
            plan_id: plan_id,
          }
        )
        .then((res) => {
          toast.success("Recharge Successful", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((error) => {
          toast.error("Something went wrong. Please contact help desk", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    },
    prefill: {
      name: name,
      email: data.notes.email,
      contact: user.country_code + user.phone_number,
    },
    method: {
      netbanking: true,
      card: true,
      wallet: true,
      upi: true,
      emi: false,
    },
  };
  var rzp1 = new window.Razorpay(options);
  rzp1.on("payment.failed", function (response) {
    toast.error(response?.error?.description, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  });
  rzp1.open();
}

export default Checkout;
