import { memo, useEffect, useState } from "react";

const InputSingleChoice = ({ ques, def, is_allowed, handler = null }) => {
  const [selectedVal, setSelectedVal] = useState(def ? def : "");
  const [manualVal, setManualVal] = useState("");
  let optList = ques.opt_list.map((o) => {
    return o.value;
  });
  useEffect(() => {
    if (!optList.includes(def) && def) {
      setSelectedVal("___manual___");
      setManualVal(def);
    }
    return () => {};
  }, [def]);
  return (
    <div className=" mb-4">
      <div className="flex flex-col justify-start">
        {ques.opt_list.map((o) => {
          if (o.value == "___manual___") {
            return (
              <label
                key={o.id}
                className="inline-flex items-center radio-container mb-1"
              >
                <input
                  name={ques.id}
                  type="text"
                  id={o.id}
                  value={manualVal}
                  placeholder="Enter Manually"
                  onChange={(e) => {
                    if (is_allowed) {
                      setManualVal(e.target.value);
                      if (handler) handler(e.target.value);
                    }
                  }}
                  disabled={!is_allowed}
                />
                <input
                  name={ques.id}
                  type="radio"
                  id={o.id}
                  value={selectedVal}
                  className="peer hidden"
                  checked={selectedVal == o.value}
                  onChange={(e) => {
                    if (is_allowed) {
                      setSelectedVal(selectedVal);
                      if (handler) handler(manualVal);
                    }
                  }}
                  disabled={!is_allowed}
                />
                <span className="radio peer-checked:bg-blue-500 peer-checked:after:block"></span>
              </label>
            );
          } else {
            return (
              <label
                key={o.id}
                className="inline-flex items-center radio-container mb-1"
              >
                {o.value}
                <input
                  name={ques.id}
                  type="radio"
                  id={o.id}
                  value={selectedVal}
                  className="peer hidden"
                  checked={selectedVal == o.value}
                  onChange={(e) => {
                    setSelectedVal(o.value);
                    handler(o.value);
                  }}
                  disabled={!is_allowed}
                />
                <span className="radio peer-checked:bg-blue-500 peer-checked:after:block"></span>
              </label>
            );
          }
        })}
      </div>
    </div>
  );
};

export default InputSingleChoice;

function propsAreEqual(prev, next) {
  return prev.ques === next.ques && prev.def === next.def;
}

export const MemoInputSingleChoice = memo(InputSingleChoice, propsAreEqual);
