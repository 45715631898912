import { createContext, useEffect, useReducer } from "react";
import FormCard from "components/forms/FormCard";
import FormTemplate from "components/forms/FormTemplate";
import useBase from "hooks/useBase";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { URL_FORM_LIST } from "constants";

export const FormContext = createContext();

function reducerForm(state, action) {
  switch (action.type) {
    case "fetch":
      return action.data;
    case "rename":
      Object.entries(state.forms).map(([k, v]) => {
        if (v.id === action.form_id) {
          const newItem = { ...v, name: action.name };
          return { ...state, forms: state.forms.splice(k, 1, newItem) };
        }
        return null;
      });
      return { ...state, forms: state.forms };
    case "delete":
      return {
        ...state,
        forms: state.forms.filter((item) => item.id !== action.id),
      };
    case "detachShp":
      let forms = [];
      state.forms.forEach((f) => {
        if (f.id === action.id) {
          forms.push({ ...f, shp: null, shp_id: null });
        } else {
          forms.push(f);
        }
      });
      if (forms.length > 0) {
        return { ...state, forms: forms };
      }
      return state;
    default:
      return { ...state, forms: state.forms };
  }
}

const Forms = () => {
  const [state, dispatch] = useReducer(reducerForm, {
    forms: [],
    shp: [],
  });
  const { proj } = useBase()
  const axiosPrivate = useAxiosPrivate()

  useEffect(() => {
    const fetch = async () => {
      await axiosPrivate
        .get(`${URL_FORM_LIST}/${proj?.id}`)
        .then((res) => {
          dispatch({ type: "fetch", data: res.data.data });
        })
        .catch((error) => { });
    };
    fetch()
    document.title = "Geodit | Forms";
  }, [])
  if (proj?.access_level < 3)
    return (
      <div>
        <FormContext.Provider value={{ state }}>
          <FormTemplate forms={state.forms} shp={state.shp} />
          <div className="pb-4">
            {state.forms?.length > 0 && (
              <h6>
                <strong className="text-l">Forms</strong>
              </h6>
            )}
            <div className="sm:grid grid-cols-2 sm:grid-cols-3  lg:grid-cols-5 gap-4 mt-2">
              {state.forms?.length > 0
                ? state.forms.map((key, index) => {
                  return <FormCard key={key.id} index={index} data={key} dispatch={dispatch} />;
                })
                : ""}
            </div>
          </div>
        </FormContext.Provider>
      </div>
    );
  else
    return (
      <span className="w-100 text-center text-sm">
        You are not authorized to access this page. Please ask project admin to
        change the privilage
      </span>
    );
};

export default Forms;
