import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
} from "@react-google-maps/api";
import { memo, useEffect, useRef, useState } from "react";

const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

// const libraries = ["places"]
const center = {
  lat: 30.4036,
  lng: 74.028,
};

const options = {
  disableDefaultUI: true,
};

const libraries = ["places"];

function InputLocation({ ques, def, is_allowed, handler = null }) {
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCXVJ8lvesuLbTJrMXADKo9KOxl8c-4Bg4",
    libraries,
  });

  const [marker, setMarker] = useState();
  const [selected, setSelected] = useState(null);
  const mapRef = useRef();

  useEffect(() => {
    try {
      if (def && def.includes(":")) {
        let coord = def.split(":");
        let lat = coord[0];
        let lng = coord[1];
        setMarker({ lat: parseFloat(lat), lng: parseFloat(lng) });
      }
    } catch {}
    return () => {};
  }, []);

  const onMapLoad = (map) => {
    mapRef.current = map;
    if (marker) {
      mapRef.current.panTo(marker);
      mapRef.current.setZoom(16);
    }
  };

  const markerFunction = (e) => {
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();
    setMarker({ lat: lat, lng: lng });
    setSelected(null);
    handler(`${lat}:${lng}`);
  };

  return (
    <div className="mb-4">
      <h6 className="text-center small">
        {is_allowed && "Click on the map to add location"}
      </h6>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={14}
          center={marker ? marker : center}
          options={options}
          onClick={markerFunction}
          onLoad={onMapLoad}
        >
          {marker && (
            <Marker
              position={{ lat: marker?.lat, lng: marker?.lng }}
              draggable={true}
              onDragEnd={markerFunction}
              onClick={() => {
                if (is_allowed) setSelected(marker);
              }}
            />
          )}

          {selected ? (
            <InfoWindow
              position={{ lat: selected?.lat, lng: selected?.lng }}
              onCloseClick={() => {
                setSelected(null);
              }}
            >
              <div>
                <h6 className="small">Lat:{selected.lat}</h6>
                <h6 className="small">Lng:{selected.lng}</h6>
              </div>
            </InfoWindow>
          ) : null}
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
}
export default InputLocation;

function propsAreEqual(prev, next) {
  return prev.ques === next.ques && prev.def === next.def;
}

export const MemoInputLocation = memo(InputLocation, propsAreEqual);
