import Layout from "components/layout/Layout";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Dashboard from "pages/Dashboard";
import { ToastContainer } from "react-toastify";
import { Suspense, lazy } from "react";
import useAuth from "hooks/useAuth";
import Base from "components/Base";
import Overview from "pages/Overview";
import Team from "pages/Team";
// import Map from "pages/Map";
import Forms from "pages/Forms";
// import Edit from "components/forms/Edit";
import Responses from "pages/Responses";
import Settings from "pages/Settings";
// const Overview = lazy(() => import("pages/Overview"));
// const Team = lazy(() => import("pages/Team"));
// const Forms = lazy(() => import("pages/Forms"));
const Edit = lazy(() => import("components/forms/Edit"));
const Map = lazy(() => import("pages/Map"));
// const Responses = lazy(() => import("pages/Responses"));


const Landing = lazy(() => import("pages/Landing"));

const RequireAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();
  return (
    auth?.access
      ? <Outlet />
      : <Navigate to="" state={{ from: location }} replace />
  );
}

// const libraries = ["places"];

function App() {
  // const { isLoaded, loadError } = useLoadScript({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyCXVJ8lvesuLbTJrMXADKo9KOxl8c-4Bg4",
  //   libraries: libraries,
  // });
  return (

    <Suspense fallback={<div>Loading...</div>}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
        theme="colored"
      />
      <Routes>
        <Route element={<Layout />}>
          <Route path={""} element={<Landing />} />
          <Route element={<RequireAuth />}>
            <Route path={"dashboard"} element={<Dashboard />} />
            <Route path="/:slug/*" element={<Base />} >
              <Route path={"overview"} element={<Overview />} />
              <Route path={"team"} element={<Team />} />
              <Route path={"map"} element={<Map />} />
              <Route path={"forms"} element={<Forms />} />
              <Route path={":id/forms/edit"} element={<Edit />} />
              <Route path={"data"} element={<Responses />} />
              <Route path={"settings"} element={<Settings />} />
            </Route>
          </Route>
        </Route>
        {/* <Route path={""} element={<LoggedInRoute />} />
          <Route path={"/dashboard"} element={<LoggedInRoute />} />
          <Route path={"/:slug/overview"} element={<LoggedInProjRoute />} />
          <Route path={"/:slug/team"} element={<LoggedInProjRoute />} />
          <Route path={"/:slug/map"} element={<LoggedInProjRoute />} />
          <Route path={"/:slug/forms"} element={<LoggedInProjRoute />} />
          <Route
            path={"/:slug/"}
            element={<LoggedInProjRoute />}
          />
          <Route path={"/:slug/responses"} element={<LoggedInProjRoute />} />
          <Route path={"/:slug/settings"} element={<LoggedInProjRoute />} /> */}
      </Routes>
    </Suspense>
  );
}

export default App;
