import { useEffect, useState } from "react";
import { format, subDays } from "date-fns";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import DatePicker from "components/common/DatePicker";
import Dropdown from "components/common/Dropdown";
import useAxios from "hooks/useAxios";
import { URL_CHARTS } from "constants";
import useBase from "hooks/useBase";
import { toast } from "react-toastify";

let chartParams = {
  startDate: format(subDays(new Date(), 29), "yyyy-MM-dd"),
  endDate: format(new Date(), "yyyy-MM-dd"),
  type: "weekly",
  form: "all",
  team: "all",
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="px-4 py-2 bg-white">
        <p className="">{`${label}`}</p>
        {payload
          .slice()
          .reverse()
          .map((p, i) => {
            return (
              <p
                key={i}
                className="mb-1 text-sm font-medium	"
                style={{ color: p.fill }}
              >{`${p.dataKey} : ${p.value}`}</p>
            );
          })}
      </div>
    );
  }

  return null;
};

const Charts = ({ formList }) => {

  const { proj, apiUrl } = useBase()
  const [chart, setChart] = useState({})
  const axiosPrivate = useAxios(apiUrl)

  async function sendRequest(params) {
    await axiosPrivate
      .get(
        `${URL_CHARTS}/${proj.id}`, { params: params }
      )
      .then((res) => {
        setChart(res?.data?.data)
        // dispatch({ type: "deleteProj", id: id });
      })
      .catch((error) => {
        toast.error("Something went wrong. Please contact help desk", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  let formOpt = [{ label: "All Forms", value: "all" }];

  if (formList) {
    formOpt = [...formOpt, ...formList];
  }
  useEffect(() => {
    chartParams = {
      startDate: format(subDays(new Date(), 29), "yyyy-MM-dd"),
      endDate: format(new Date(), "yyyy-MM-dd"),
      type: "weekly",
      form: "all",
      team: "all",
    };
    sendRequest(chartParams);
    return () => { };
  }, []);

  const handleTypeChange = (e) => {
    chartParams = { ...chartParams, type: e.target.id };
    sendRequest(chartParams);
  };
  const handleTimeChange = (s, e) => {
    chartParams = {
      ...chartParams,
      startDate: format(s, "yyyy-MM-dd"),
      endDate: format(e, "yyyy-MM-dd"),
    };
    sendRequest(chartParams);
  };
  const handleFormChange = (e) => {
    chartParams = { ...chartParams, form: e.value };
    sendRequest(chartParams);
  };

  return (
    <div>
    <div className="boxes p-3 border mt-0">
      <div className="md:flex justify-between items-center">
        <div className="sm:flex gap-4">
          <DatePicker onChange={handleTimeChange} w="w-48"/>
          <div className="flex items-center my-4 sm:my-0">
            <div>
              <input
                type="radio"
                className="hidden peer"
                name="options-outlined"
                id="daily"
                autoComplete="off"
                onChange={handleTypeChange}
              />
              <label
                className="time-btn peer-checked:bg-yellow-400  peer-checked:text-yellow-900 border-r-0"
                htmlFor="daily"
              >
                Daily
              </label>
            </div>
            <div>
              <input
                type="radio"
                className="hidden peer"
                name="options-outlined"
                id="weekly"
                autoComplete="off"
                defaultChecked
                onChange={handleTypeChange}
              />
              <label
                className="time-btn peer-checked:bg-yellow-400  peer-checked:text-yellow-900 border-r-0"
                htmlFor="weekly"
              >
                Weekly
              </label>
            </div>
            <div>
              <input
                type="radio"
                className="hidden peer"
                name="options-outlined"
                id="monthly"
                autoComplete="off"
                onChange={handleTypeChange}
              />
              <label
                className="time-btn peer-checked:bg-yellow-400  peer-checked:text-yellow-900"
                htmlFor="monthly"
              >
                Monthly
              </label>
            </div>
          </div>
        </div>
        <div className="mt-2 md:mt-0">
          <Dropdown opt={formOpt} handler={handleFormChange} />
        </div>
      </div>
    </div>
    <div className="boxes h-96" id="overview-chart">   
    {chart?.data && chart?.data?.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chart.data}
              margin={{ top: 10, right: 20, left: -15, bottom: 0 }}
            >
              <XAxis dataKey="month" />
              <YAxis allowDecimals={false} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="count"
                stroke="#eab308"
                strokeWidth="3px"
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <span className="font-12 mx-4">No data available</span>
        )}
      </div>
      <div className="boxes h-96 text-xs px-0" id="overview-chart">
        {chart?.surveyors && chart?.surveyors.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chart.surveyors}
              margin={{ top: 15, right: 20, left: -15, bottom: 0 }}
            >
              <Legend iconSize={8}/>
              <XAxis dataKey="name" />
              <YAxis allowDecimals={false} />
              <CartesianGrid strokeDasharray="3 3"  vertical={false} />
              <Tooltip content={<CustomTooltip />} />

              <Bar
                stackId="a"
                type="monotone"
                dataKey="Unchecked"
                fill="#eab308"
              />
              <Bar
                stackId="a"
                type="monotone"
                dataKey="Approved"
                fill="#82ca9d"
              />
              <Bar
                stackId="a"
                type="monotone"
                dataKey="Incorrect"
                fill="#ff2e2e"
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <span className="font-12  mx-4">No data available</span>
        )}
      </div>
      </div>
  );
};
export default Charts;
