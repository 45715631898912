import { memo, useEffect, useRef, useState } from "react";
import { AiOutlineFileAdd, AiOutlineCopy } from "react-icons/ai";
import Modal from "components/common//Modal";
import { MaxChar, NameValidator } from "components/common/Validators";
import { URL_FORM_CREATE, URL_FORM_COPY } from "constants";

import Dropdown from "components/common/Dropdown";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBase from "hooks/useBase";
import { useNavigate } from "react-router-dom";
import { URL_FORM_LIST_COPY } from "constants";
const templates = [
  {
    id: "prop_sur",
    heading: "Add Property Survey Form",
    name: "Property Survey",
    desc: "Multi purpose base template for Property Survey",
  },
  {
    id: "amrut_buildings",
    heading: "Add AMRUT Building Survey Form",
    name: "Building Survey",
    desc: "Building Landuse survey template designed as per AMRUT guidelines",
  },
  {
    id: "amrut_roads",
    heading: "Add AMRUT Road Survey Form",
    name: "Road Survey",
    desc: "AMRUT template for Road Survey",
  },
  {
    id: "od_survey",
    heading: "Add OD Survey Form",
    name: "OD Survey",
    desc: "All in one Origin Destination Survey",
  },
  {
    id: "tv_survey",
    heading: "Add Traffic Volume Survey Form",
    name: "TV Survey",
    desc: "General Purpose Traffic Volume Survey",
  },
];

const FormTemplate = memo(({ forms, shp }) => {
  const axiosPrivate = useAxiosPrivate()
  const { proj } = useBase()
  const navigate = useNavigate();

  const [showAllForms, setshowAllForms] = useState(false);
  const [allForms, setAllForms] = useState([]);
  const [fieldError, setFieldError] = useState({ field: null, error: null });
  const refModal = useRef(null);
  const refTemplate = useRef("blank");

  let formList = [];
  let shpOpt = [{ value: "", label: "None" }];
  let selectedShp = { value: "", label: "None" };
  forms?.forEach((f) => {
    formList.push(f.id);
  });
  shp?.forEach((s) => {
    if (!formList.includes(s.form)) {
      shpOpt.push({ value: s.id, label: s.name });
    }
  });

  const handleModalSubmit = async (e, buttonRef) => {
    e.preventDefault();
    let name = e.target.name.value;
    let shp_id = e.target?.shp?.value;
    let f_type = refTemplate?.current;
    let form_id = e.target?.copyForm?.value;
    if (!name || name === "") {
      setFieldError({
        field: "name",
        error: "Field cannot be empty",
      });
    } else {
      let url = URL_FORM_CREATE
      let data = {
        name: name
      };
      if (shp_id && shp_id !== "") {
        data['shp_id'] = shp_id
      }
      if (f_type && f_type !== "" && f_type !== "blank") {
        data['f_type'] = f_type
      }
      if (form_id && form_id !== "" && showAllForms) {
        data['form_id'] = form_id
        url = URL_FORM_COPY
      }
      await axiosPrivate
        .post(`${url}/${proj?.id}`, data)
        .then((res) => {
        if (buttonRef.current) buttonRef.current.disabled = false;
          navigate(`/${proj?.slug}/${res.data.data}/forms/edit`);
        })
        .catch((error) => { });
    }
    if (buttonRef.current) buttonRef.current.disabled = false;
  };

  useEffect(() => {
    const fetch = async () => {
      await axiosPrivate
        .get(`${URL_FORM_LIST_COPY}/${proj?.id}`)
        .then((res) => {
          let groupProj = {};
          res.data.data.forEach((f) => {
            if (f.proj__id in groupProj) {
              groupProj[f.proj__id].push(f);
            } else {
              groupProj[f.proj__id] = [f];
            }
          });
          let finalAllFormOpt = [];
          Object.values(groupProj).map((v, _) => {
            // let opt = { label: v[0].proj__name, options: [] };
            v.forEach((f) => {
              finalAllFormOpt.push({ value: f.id, label: `${f.proj__name} - ${f.name}` });
            });
            // finalAllFormOpt.push(opt);
          });
          setAllForms(finalAllFormOpt);
        })
        .catch((error) => { });
    };
    fetch()

  }, []);

  // useEffect(() => {
  //   let finalAllFormOpt = [];
  //   forms.forEach((f) => {
  //     finalAllFormOpt.push({ value: f.id, label: f.name });
  //   });
  //   setAllForms(finalAllFormOpt);
  //   return () => { };
  // }, [forms]);


  return (
    <div className="overflow-auto max-w-100">
      <div className="md:grid mb-4 pb-4 border-gray-300 border-b gap-2 grid-cols-10">
        <div className="flex flex-col gap-2 border-gray-300 border-r pr-2 h-48 col-span-2 mb-2 md:mb-0">
          <div
            id="blank"
            onClick={(e) => {
              setshowAllForms(false);
              refModal.current.open();
              refTemplate.current = "blank";
            }}
            className="card flex flex-col items-center h-auto flex-1"
          >
            <AiOutlineFileAdd
              title="Add New Form"
              className="text-2xl flex-1 pt-4"
            />
            <h6 className="mt-1 text-sm flex-1">Add Blank Form</h6>
          </div>
          {allForms.length > 0 && (
            <div
              id="copy"
              onClick={(e) => {
                setshowAllForms(true);
                refModal.current.open();
                refTemplate.current = "blank";
              }}
              className="card flex flex-col items-center h-auto flex-1"
            >
              <AiOutlineCopy
                title="Copy Form"
                className="text-2xl flex-1 pt-4"
              />
              <h6 className="mt-1 text-sm flex-1">Copy Form</h6>
            </div>
          )}
        </div>
        <div className=" col-span-8 flex">
          <section
            className="overflow-auto"
          >

            <div className="flex gap-4 flex-nowrap">
              {templates.map((d) => {
                return (
                  <div
                    onClick={(e) => {
                      setshowAllForms(false);
                      refModal.current.open();
                      refTemplate.current = d.id;
                    }}
                    key={d.id}
                    id={d.id}
                    className="card h-48 w-52 flex-none"
                  >
                    <div>
                      <div className="py-2 border-b shadow-md">
                        <h6 className="mb-0 text-md text-center">{d.name}</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>

        </div>
      </div>
      <Modal
        ref={refModal}
        heading={"Create new form"}
        fields={
          <div className="flex flex-col mb-6">
            <div className="flex gap-4">
              <div className="flex flex-1">
                <span className="input-text mb-0">Form Name</span>
                <div className="flex-1 relative">
                  <input
                    className="input mb-0"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter name here"
                    required
                    onInput={(e) => {
                      MaxChar(e, 25);
                      if (fieldError.field === "name") setFieldError({});
                    }}
                    onKeyPress={NameValidator}
                  />
                  {fieldError.field === "name" && (
                    <div
                      className="invalid-tooltip"
                      id={`error-${fieldError.field}`}
                    >
                      {fieldError.error}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-1 flex">
                <span className="input-text mb-0">Attach to Shapefile</span>
                <Dropdown
                  opt={shpOpt}
                  name="shp"
                  id="shp"
                  w="w-full"
                  isDisabled={shpOpt.length < 2 ? true : false}
                  def={selectedShp}
                />

              </div>
            </div>
            {shpOpt.length < 2 && (
              <span className="text-xxs text-right mt-1">
                All shapefiles have been attached.
              </span>
            )}
            {showAllForms && (
              <div className="flex-1 flex mt-4">
                <span className="input-text mb-0">Form List</span>
                <Dropdown opt={allForms} id="copyForm" w="w-full" />
              </div>
            )}
          </div>
        }
        handleSubmit={handleModalSubmit}
        width="44rem"
      />
    </div>
  );
});

export default FormTemplate;
