// import { GetInput } from "./GetInput";

import { MemoInputDate } from "./InputDate";
import { MemoInputDefault } from "./InputDefault";
import { MemoInputDropdown } from "./InputDropdown";
import { MemoInputImage } from "./InputImage";
import { MemoInputLocation } from "./InputLocation";
import { MemoInputMultiChoice } from "./InputMultiChoice";
import { MemoInputPara } from "./InputPara";
import { MemoInputPhone } from "./InputPhone";
import { MemoInputRating } from "./InputRating";
import { MemoInputSingleChoice } from "./InputSingleChoice";
import { MemoInputTime } from "./InputTime";

function GetInput(ques, def, is_allowed, handler = null) {
  const props = {
    ques: ques,
    def: def,
    is_allowed: is_allowed,
    handler: handler,
  };
  switch (ques.q_type) {
    case "ele_date":
      return <MemoInputDate {...props} />;
    case "ele_time":
      return <MemoInputTime {...props} />;
    case "ele_single_choice":
      return <MemoInputSingleChoice {...props} />;
    case "ele_dropdown":
      return <MemoInputDropdown {...props} />;
    case "ele_multiple_choice":
      return <MemoInputMultiChoice {...props} />;
    case "ele_para":
      return <MemoInputPara {...props} />;
    case "ele_phone":
      return <MemoInputPhone {...props} />;
    case "ele_rating":
      return <MemoInputRating {...props} />;
    case "ele_location":
      return <MemoInputLocation {...props} />;
    case "ele_image":
      return <MemoInputImage {...props} />;
    default:
      return <MemoInputDefault {...props} />;
  }
}

const Ans = ({ ques, def, is_allowed, handler }) => {
  return (
    <div className="text-sm">
      Q{ques.position}: {ques.question}{" "}
      {ques.mand && <span className="text-red-600">*</span>}
      {GetInput(ques, def, is_allowed, handler)}
    </div>
  );
};

export default Ans;
