
import { format, subDays } from "date-fns";
import { forwardRef, useContext, useEffect, useState } from "react";
import Modal from "components/common//Modal";

import DatePicker from "components/common/DatePicker";
import { toast } from "react-toastify";
import Dropdown from "components/common/Dropdown";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { ResponsesContext } from "pages/Responses";
import { URL_DATA_DOWNLOAD } from "constants";
import Map from "./Map";


let dataOpt = [
  { label: "Attributes Only", value: "attr" },
  { label: "Images Only", value: "img" },
  { label: "All Data", value: "all" },
];

let featOpt = [
  { label: "Collected Only", value: "collected" },
  { label: "All", value: null },
];

let saOpt = [
  { label: "All", value: "all" },
  { label: "Select from map", value: "map" },
];

let delOpt = [
  { label: "Dont't Incluce", value: false },
  { label: "Include", value: true },
];

let xlsxFilterByOpt = [
  { label: "None", value: "none" },
  { label: "Question", value: "ques" },
];

let shpFilterByOpt = [
  { label: "None", value: "none" },
  { label: "Survey Area", value: "sur_area" },
];

let params = {
  format: null,
  sur: "all",
  feat:"collected",
  start_date: format(subDays(new Date(), 2), "yyyy-MM-dd"),
  end_date: format(new Date(), "yyyy-MM-dd"),
  form: "all",
  shp: "all",
  data_type: "attr",
  include_del: false,
  filter_by: "none",
  filter_key: null,
  filter_value: null,
  sa: "all",
  sa_list: [],

};

function DropdownDefVal(opt, val) {
  let obj = opt[0];
  Object.entries(opt).map(([k, v]) => {
    if (v.value === val) {
      obj = v;
    }
    return null;
  });
  return obj;
}

const Download = forwardRef(({ data }, ref) => {

  const axiosPrivate = useAxiosPrivate()

  const ctx = useContext(ResponsesContext);
  const proj = ctx?.proj
  const state = ctx?.state
  const dispatch = ctx?.dispatch
  const shpCount = state?.shpCount
  const formList = state?.formList
  const shpList = state?.shpList
  const quesList = state?.quesList
  const [formatOpt, setFormatOpt] = useState([]);
  const [surveyorOpt, setSurveyorOpt] = useState([{ label: "All", value: "all" }, { label: "None", value: "-" }]);
  const [selQuesList, setSelQuesList] = useState([{ label: "Select....", value: null }]);
  const [selForm, setSelForm] = useState(null);
  const [downloadOpt, setDownloadOpt] = useState(params);
  useEffect(()=>{
    if(state?.teamList)
    setSurveyorOpt([{ label: "All", value: "all" }, { label: "None", value: "-" }, ...state?.teamList])
  },[state?.teamList])
  useEffect(() => {
    let opt = [
      // { label: ".kml", value: "kml" },
    ];
    if (shpCount && shpCount > 0 && proj?.use_map) {
      opt.push({ label: ".shp", value: "shp" });
      setDownloadOpt(prev=>({...prev, format:"shp"}))
    } else {
      opt.push({ label: ".xlsx", value: "excel" });
      setDownloadOpt(prev=>({...prev, format:"excel"}))
    }
    setFormatOpt(opt);
    return () => { };
  }, [shpCount]);

  const handleTimeChange = (s, e) => {
    setDownloadOpt((prev) => ({
      ...prev,
      start_date: format(s, "yyyy-MM-dd"),
      end_date: format(e, "yyyy-MM-dd"),
    }));
  };
  useEffect(() => {
    if (quesList.hasOwnProperty(selForm))
      setSelQuesList([{ label: "Select....", value: null }, ...quesList[selForm]])
    else
      setSelQuesList([{ label: "Select....", value: null }])
  }, [selForm])

  useEffect(() => {
    if (formList.length > 0) {
      setSelForm(formList[0].value)
      setDownloadOpt((prev) => {
        return { ...prev, form: formList[0].value };
      })
    }

  }, [formList])

  const handleModalSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "removeDownloadUrls", data: null });

    if (downloadOpt.sa === "map" && downloadOpt.sa_list.length < 1) {
      toast.warning("Please select atleast one survey area.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      await axiosPrivate
        .post(`${URL_DATA_DOWNLOAD}/${proj?.id}`, downloadOpt)
        .then((res) => {
          toast.info("We will notify you once download link is ready.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((error) => {
          let message = "Something went wrong. Please try again."
          if (error?.response?.data?.message)
            message = error?.response?.data?.message
          toast.error(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      ref?.current?.close();
      // setDownloadOpt(params);
    }
  }
  return (
    <Modal
      ref={ref}
      heading={"Generate Download Link"}
      fields={
        <div className="flex">
          <div className="px-4 pb-3 justify-between flex-1">
            <div className="flex justify-between gap-2">
              <div className="flex flex-1">
                <span className="input-text mb-0 h-9">Format</span>
                <Dropdown
                  opt={formatOpt}
                  def={DropdownDefVal(formatOpt, downloadOpt?.format)}
                  text="text-xs"
                  h=" h-9 py-0"
                  w="flex-1"
                  handler={(e, buttonRef) => {
                    if (e.value === "shp") {
                      setDownloadOpt((prev) => ({ ...prev, format: e.value, shp: shpList[0].value, filter_by: "none" }))
                    }
                    else {
                      setSelForm(formList[0].form)
                      setDownloadOpt((prev) => ({ ...prev, format: e.value, filter_by: "none" }))
                    }
                    if (buttonRef && buttonRef.current) buttonRef.current.disabled = false;
                  }
                  }
                />
              </div>
              {downloadOpt?.format === "shp" && (
              <div className="flex flex-1">
                <span className="input-text mb-0 h-9">Features</span>
                <Dropdown
                  opt={featOpt}
                  def={DropdownDefVal(featOpt, downloadOpt?.feat)}
                  text="text-xs"
                  h=" h-9 py-0"
                  w="flex-1"
                  handler={(e, buttonRef) => {
                    setDownloadOpt((prev) => ({ ...prev, feat: e.value }))
                    if (buttonRef && buttonRef.current) buttonRef.current.disabled = false;
                  }
                  }
                />
              </div>)}
            </div>
            {downloadOpt?.format === "excel" && (
            <div className="grid grid-cols-1 justify-between gap-2 mt-3 ">
              <div className="flex">
                <span className="input-text mb-0 h-9">Surveyor</span>
                <Dropdown
                  opt={surveyorOpt}
                  def={DropdownDefVal(surveyorOpt, downloadOpt?.sur)}
                  text="text-xs"
                  h=" h-9 py-0"
                  w="flex-1"
                  handler={(e, buttonRef) => {
                    setDownloadOpt((prev) => ({ ...prev, sur: e.value}))
                  
                    if (buttonRef && buttonRef.current) buttonRef.current.disabled = false;
                  }
                  }
                />
              </div>
            </div>
            )}
            <div className="grid grid-cols-3 text-sm justify-between mt-3 gap-2">
              {downloadOpt?.format === "excel" && (
                <DatePicker
                  onChange={handleTimeChange}
                  text="text-xs"
                  days={2}
                />
              )}
              {downloadOpt?.format === "excel" ? (
                <div className="flex">
                  <span className="input-text mb-0 h-9">Forms</span>
                  <Dropdown
                    opt={formList}
                    def={DropdownDefVal(formList, selForm)}
                    text="text-xs"
                    h=" h-9 py-0"
                    w="flex-1"
                    handler={(e) => {
                      setSelForm(e.value);
                      setDownloadOpt((prev) => {
                        return { ...prev, form: e.value };
                      })
                    }
                    }
                  />
                </div>
              ) : (
                <div className="flex">
                  <span className="input-text mb-0 h-9">Shapefile</span>
                  <Dropdown
                    opt={shpList}
                    def={DropdownDefVal(shpList, downloadOpt?.shp)}
                    text="text-xs"
                    h=" h-9 py-0"
                    w="flex-1"
                    handler={(e) =>
                      setDownloadOpt((prev) => ({ ...prev, shp: e.value }))
                    }
                  />
                </div>
              )}
              <div className="flex">
                <span className="input-text mb-0 h-9">Data</span>
                <Dropdown
                  opt={dataOpt}
                  def={DropdownDefVal(dataOpt, downloadOpt?.data_type)}
                  text="text-xs"
                  h="h-9 py-0"
                  w="flex-1"
                  handler={(e) =>
                    setDownloadOpt((prev) => ({ ...prev, data_type: e.value }))
                  }
                />
              </div>
              {downloadOpt?.format !== "excel" && (
                <div className="flex flex-1 col-span-1">
                  <span className="input-text mb-0 h-9">Deleted</span>
                  <Dropdown
                    opt={delOpt}
                    def={DropdownDefVal(delOpt, downloadOpt?.include_del)}
                    text="text-xs"
                    h=" h-9 py-0"
                    w="flex-1"
                    // disabled={!shpCount || !shpCount > 0 || !isMap}
                    handler={(e) =>
                      setDownloadOpt((prev) => ({
                        ...prev,
                        include_del: e.value,
                      }))
                    }
                  />
                </div>
              )}
            </div>

            <div className="mt-3">
              {(downloadOpt.form !== "all" || downloadOpt.format === "shp") && <div>
                <div className="flex gap-2">
                <div className="flex flex-1">
                  <span className="input-text mb-0 h-9">Filter By</span>
                  <Dropdown
                    opt={downloadOpt?.format === "excel" ? xlsxFilterByOpt : shpFilterByOpt}
                    def={downloadOpt?.format === "excel" ? DropdownDefVal(xlsxFilterByOpt, downloadOpt?.filter_by) : DropdownDefVal(shpFilterByOpt, downloadOpt?.filter_by)}
                    text="text-xs"
                    h=" h-9 py-0"
                    w="flex-1"
                    handler={(e) =>
                      setDownloadOpt((prev) => ({ ...prev, filter_by: e.value }))
                    }
                  />
                </div>
                {downloadOpt?.format !== "excel" && downloadOpt?.feat === "collected" && (
                // data?.bbox && (
                <div className="flex">
                  <span className="input-text mb-0 h-9">Date Range</span>
                  <DatePicker
                  onChange={handleTimeChange}
                  text="text-xs"
                  days={2}
                />
                </div>
              )}
                </div>
                <div className="mt-3">
                  {downloadOpt?.filter_by === "ques" && selQuesList.length > 0 && (
                    <div className="flex">
                      <div className="flex flex-1">
                        <Dropdown
                          opt={selQuesList}
                          def={DropdownDefVal(selQuesList, downloadOpt?.filter_key)}
                          text="text-xs"
                          h=" h-9 py-0"
                          w="flex-1"
                          handler={(e) =>
                            setDownloadOpt((prev) => ({
                              ...prev,
                              filter_key: e.value,
                            }))
                          }
                        />
                      </div>
                      <span className="input-text mb-0 h-9 border-x-0">=</span>
                      <input
                        className="input mb-1 flex-1"
                        id="filter_value"
                        name="filter_value"
                        type="text"
                        placeholder="Value"
                        required
                        onInput={(e) => {
                          setDownloadOpt((prev) => ({
                            ...prev,
                            filter_value: e.target.value,
                          }))
                        }}
                      />
                    </div>
                  )}
                  {downloadOpt?.filter_by === "attr" && selQuesList.length > 0 && (
                    <div className="flex">
                      <div className="flex flex-1">
                        <Dropdown
                          opt={selQuesList}
                          def={DropdownDefVal(selQuesList, downloadOpt?.filter_key)}
                          text="text-xs"
                          h=" h-9 py-0"
                          w="flex-1"
                          handler={(e) =>
                            setDownloadOpt((prev) => ({
                              ...prev,
                              filter_key: e.value,
                            }))
                          }
                        />
                      </div>
                      <span className="input-text mb-0 h-9 border-x-0">=</span>
                      <input
                        className="input mb-1 flex-1"
                        id="filter_value"
                        name="filter_value"
                        type="text"
                        placeholder="Value"
                        required
                        onInput={(e) => {
                          setDownloadOpt((prev) => ({
                            ...prev,
                            filter_value: e.target.value,
                          }))
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              }
              {downloadOpt?.format !== "excel" && downloadOpt?.filter_by === "sur_area" && (
                // data?.bbox && (
                <div className="flex flex-1 col-span-1">
                  <span className="input-text mb-0 h-9">Area</span>
                  <Dropdown
                    opt={saOpt}
                    def={saOpt[0]}
                    text="text-xs"
                    h=" h-9 py-0"
                    w="flex-1"
                    // disabled={!shpCount || !shpCount > 0 || !isMap}
                    handler={(e) =>
                      setDownloadOpt((prev) => ({
                        ...prev,
                        sa: e.value,
                        sa_list: [],
                      }))
                    }
                  />
                </div>
              )}
            </div>

            {downloadOpt.sa === "map" && (
              <Map
                bbox={data?.bbox}
                surArea={data?.surArea}
                setDownloadOpt={setDownloadOpt}
              />
            )}
          </div>
        </div>
      }
      handleSubmit={handleModalSubmit}
      btnText="Generate"
      width="44rem"
      closeFun={() => {
        ref?.current?.close();
        // setDownloadOpt(params);
        // setSelForm("all")
      }}
    />
  );
});

export default Download;
