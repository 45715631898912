export const STATIC_MAP_ICON_URL = "";
export const STATIC_URL = "https://geodit-static.s3.ap-south-1.amazonaws.com/";

const ACC_API = "users/";
export const URL_REG = ACC_API + "register";
export const URL_LOGIN = ACC_API + "login";
export const URL_LOGOUT = ACC_API + "logout";
export const URL_SEND_OTP = ACC_API + "send-otp";
export const URL_VERIFY_OTP = ACC_API + "verify-otp";
export const URL_RESET_PASS = ACC_API + "password-reset";

export const URL_PLAN_LIST = ACC_API + "plan-list";
export const URL_RECHARGE_LIST = ACC_API + "recharge-list";
export const URL_CURRENT_PLAN = ACC_API + "current-plan";
export const URL_INITIATE_RECHARGE = ACC_API + "initiate-recharge";
export const URL_VERIFY_RECHARGE = ACC_API + "verify-create-recharge";
export const URL_NOTIFICATION = ACC_API + "notifications";



const PROJECTS_API = "projects/";

export const URL_PROJECTS = PROJECTS_API + "list";
export const URL_PROJECT_DETAIL = PROJECTS_API + "detail";
export const URL_PROJECT_EDIT = PROJECTS_API + "edit";
export const URL_PROJECT_CREATE = PROJECTS_API + "create";
export const URL_PROJECT_REMOVE = PROJECTS_API + "remove";
export const URL_PROJECT_LEAVE = PROJECTS_API + "leave";


const OVERVIEW_API = "overview/";

export const URL_OVERVIEW = OVERVIEW_API + "";
export const URL_OVERVIEW_ANS_COUNT = OVERVIEW_API + "ans-count";
export const URL_CHARTS = OVERVIEW_API + "charts";

const TEAM_API = "teams/";

export const URL_TEAM_LIST = TEAM_API + "list";
export const URL_TEAM_QUES_LIST = TEAM_API + "ques-list";
export const URL_TEAM_ADD_MEMBER = TEAM_API + "add-member";
export const URL_TEAM_REMOVE_MEMBER = TEAM_API + "remove-member";
export const URL_TEAM_UPDATE_MEMBER = TEAM_API + "update-member";
export const URL_TEAM_ASSIGN_DATA = TEAM_API + "assign-data";
export const URL_TEAM_DELETE_DATA = TEAM_API + "remove-data";

const MAP_API = "map/";
export const URL_MAP = MAP_API + "";
export const URL_ASSIGN = MAP_API + "assign";

export const URL_SHP_CREATE = MAP_API + "create";
export const URL_SHP_UPLOAD = MAP_API + "upload";

export const URL_SHP_SEARCH = MAP_API + "search";
export const URL_SHP_POSITION = MAP_API + "position";
export const URL_SHP_SYMBOLOGY = MAP_API + "symbology";
export const URL_SHP_SYMBOLOGY_CAT = MAP_API + "symbology-cat";
export const URL_SHP_PROP = MAP_API + "properties";
export const URL_SHP_DELETE = MAP_API + "delete";
export const URL_SHP_FEAT_COUNT = MAP_API + "feat-count";
export const URL_SHP_ATTR = MAP_API + "attr";
export const URL_SHP_ADD_ATTR = MAP_API + "add-attr";
export const URL_SHP_EDIT_ATTR = MAP_API + "edit-attr";
export const URL_SHP_EDIT_ATTR_FORM = MAP_API + "edit-attr-form";
export const URL_SHP_DEL_ATTR = MAP_API + "del-attr";
export const URL_SHP_CALC_ATTR = MAP_API + "calc-attr";
export const URL_SHP_LIST_FEAT = MAP_API + "list-feat";
export const URL_SHP_CREATE_FEAT = MAP_API + "create-feat";
export const URL_SHP_EDIT_FEAT = MAP_API + "edit-feat";
export const URL_SHP_DEL_FEAT = MAP_API + "del-feat";

export const URL_VT = MAP_API + "tiles";


const FORM_API = "forms/";
export const URL_FORM_LIST = FORM_API + "list";
export const URL_FORM_LIST_COPY = FORM_API + "list-copy";
export const URL_FORM_CREATE = FORM_API + "create";
export const URL_FORM_COPY = FORM_API + "copy";
export const URL_FORM_UPDATE = FORM_API + "update";
export const URL_FORM_DETACH = FORM_API + "detach";
export const URL_FORM_DELETE = FORM_API + "delete";
export const URL_FORM_SEND = FORM_API + "send";
export const URL_FORM_UPDATE_PRIMARY = FORM_API + "update-primary";
export const URL_FORM_PAGES = FORM_API + "pages";
export const URL_FORM_PAGE_ADD = FORM_API + "page-add";
export const URL_FORM_PAGE_EDIT_NAME = FORM_API + "page-edit-name";
export const URL_FORM_PAGE_COPY = FORM_API + "page-copy";
export const URL_FORM_PAGE_DELETE = FORM_API + "page-delete";
export const URL_FORM_PAGE_MOVE = FORM_API + "page-move";
export const URL_FORM_QUES_ADD = FORM_API + "ques-add";
export const URL_FORM_QUES_MOVE = FORM_API + "ques-move";
export const URL_FORM_QUES_DELETE = FORM_API + "ques-delete";
export const URL_FORM_QUES_EDIT = FORM_API + "ques-edit";
export const URL_FORM_OPT_ADD = FORM_API + "opt-add";
export const URL_FORM_OPT_EDIT = FORM_API + "opt-edit";
export const URL_FORM_OPT_DELETE = FORM_API + "opt-delete";

export const URL_FORM_RULES = FORM_API + "rules";
export const URL_FORM_RULES_ADD = FORM_API + "rules-add";
export const URL_FORM_RULES_EDIT = FORM_API + "rules-edit";
export const URL_FORM_RULES_DELETE = FORM_API + "rules-delete";
export const URL_FORM_RULES_ADD_THEN = FORM_API + "rules-add-then";
export const URL_FORM_RULES_DELETE_THEN = FORM_API + "rules-delete-then";


const DATA_API = "data/";
export const URL_DATA_DETAILS = DATA_API + "";
export const URL_DATA_LIST = DATA_API + "list";
export const URL_DATA_DOWNLOAD = DATA_API + "download";
export const URL_ANS_SHOW = DATA_API + "show";
export const URL_ANS_EDIT = DATA_API + "edit";
export const URL_ANS_REMOVE_PAGE = DATA_API + "remove-page";
export const URL_ANS_STATUS = DATA_API + "status";
export const URL_ANS_DELETE = DATA_API + "delete";
export const URL_ANS_IMG = DATA_API + "ans-img";


// export const HTTP_URL = "https://geodit.in/";
// export const WS_URL = "wss://geodit.in/ws/geodit/";

// export const STATIC_URL = "http://127.0.0.1:8000/";
// export const STATIC_MAP_ICON_URL = "http://127.0.0.1:8000/static/geodit/";

// export const HTTP_URL = "https://dev.api.geodit.in/";
export const HTTP_URL = "http://127.0.0.1:8000/";

export const WS_URL = "ws://127.0.0.1:8000/ws/geodit/";

const REACT_HTTP_API = HTTP_URL + "api/v1/";


export const REACT_VT = "api/v1/tiles/";
export const REACT_ANS_VT = "api/v1/ans-tiles/";
export const URL_FEAT_LIST = "api/v1/feat-list";
export const URL_ATTR = "api/v1/attributes";


// export const URL_FORM_LIST = REACT_HTTP_API + "form-list";

export const URL_UPLOAD_SHP = REACT_HTTP_API + "upload-shp";
// export const URL_FEAT_LIST = REACT_HTTP_API + "feat-list";
export const URL_SYM_CLASS = REACT_HTTP_API + "sym-class";

export const URL_ANS_LIST = REACT_HTTP_API + "ans-list";
// export const URL_TEAM_LIST = REACT_HTTP_API + "team-list";
export const URL_ANS = REACT_HTTP_API + "ans";

// export const URL_RESTORE = REACT_HTTP_API + "restore-feat";

// const WS_PROTOCOL = "ws://";
// const HTTP_PROTOCOL = "http://";
// const HOST = "127.0.0.1:8000";
// const REACT_WS = WS_PROTOCOL + HOST + "/ws/geodit/";
// export const REACT_STATIC = HTTP_PROTOCOL + HOST;
// export const REACT_HTTP = HTTP_PROTOCOL + HOST + "/";
// const REACT_HTTP_API = REACT_HTTP + "api/v1/";
// export const REACT_VT = REACT_HTTP_API + "tiles/";

// export const URL_WS = REACT_WS;
// export const URL_WS_PROJ = REACT_WS + "proj/";
// export const URL_WS_NAV = REACT_WS + "navdata/";
// export const URL_ALL_FORMS = REACT_HTTP_API + "get-all-forms";
// export const URL_UPLOAD_SHP = REACT_HTTP_API + "upload-shp";
// export const URL_PLANS = REACT_HTTP_API + "plans";
// export const URL_RECHARGE = REACT_HTTP_API + "recharge";
// export const URL_ATTR = REACT_HTTP_API + "attributes";
// export const URL_RESTORE = REACT_HTTP_API + "restore-feat";
// export const URL_ANS = REACT_HTTP_API + "ans";
// export const URL_ANS_DEL = REACT_HTTP_API + "ans-delete";
// export const URL_WEB_SURVEY = REACT_HTTP_API + "web-survey";

// const ACC_API = REACT_HTTP + "accounts/api/v1/";
// export const URL_LOGIN = ACC_API + "login";
// export const URL_LOGOUT = ACC_API + "logout";
// export const URL_REG = ACC_API + "register";
// export const URL_SEND_OTP = ACC_API + "send-otp";
// export const URL_VERIFY_OTP = ACC_API + "verify-otp";
// export const URL_RESET_PASS = ACC_API + "password-reset";
