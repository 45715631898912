import { Switch } from "@headlessui/react";
import { useEffect, useState } from "react";
import { ValueValidator } from "components/common/Validators";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { URL_PROJECT_EDIT } from "constants";
import useBase from "hooks/useBase";


let timeout1;
let timeout2;
let timeout3;
let timeout4;
let timeout5;

const Settings = () => {
  const axiosPrivate = useAxiosPrivate()
  const { proj, setProj } = useBase()

  const [active, setActive] = useState(proj?.is_active);
  const [useMap, setUseMap] = useState(proj?.use_map);
  const [surEdit, setSurEdit] = useState(proj?.can_sur_edit_approved_data);
  const [supEdit, setSupEdit] = useState(proj?.can_sup_edit_approved_data);
  const [onlineSurvey, setOnlineSurvey] = useState(proj?.is_online);
  const [tolerance, setTolerance] = useState(proj?.tolerance);
  const [surDataEditTime, setSurDataEditTime] = useState(proj?.sur_data_edit_window);
  const [supDataEditTime, setSupDataEditTime] = useState(proj?.sup_data_edit_window);


  useEffect(() => {
    document.title = "Geodit | Settings";
  }, []);

  function handleActiveToggle(e) {
    var value = !active
    if (timeout1) {
      clearTimeout(timeout1);
    }
    timeout1 = setTimeout(() => {
      axiosPrivate
        .post(`${URL_PROJECT_EDIT}`, { id: proj?.id, slug:proj?.slug, is_active: value })
        .then((res) => {
          setProj(prev=>{return {...prev,is_active:value}})
        })
        .catch((error) => { });
    }, 300);
    setActive((prev) => !prev);
  }

  function handleUseMapToggle(e) {
    var value = !useMap
    if (timeout2) {
      clearTimeout(timeout2);
    }
    timeout2 = setTimeout(() => {
      axiosPrivate
        .post(`${URL_PROJECT_EDIT}`, { id: proj?.id, slug:proj?.slug, use_map: value })
        .then((res) => {
          setProj(prev=>{return {...prev,use_map:value}})
        })
        .catch((error) => { });
    }, 300);
    setUseMap((prev) => !prev);
  }
  function handleUseOnlineToggle(e) {
    var value = !onlineSurvey
    if (timeout2) {
      clearTimeout(timeout2);
    }
    timeout2 = setTimeout(() => {
      axiosPrivate
        .post(`${URL_PROJECT_EDIT}`, { id: proj?.id, slug:proj?.slug, is_online: value })
        .then((res) => {
          setProj(prev=>{return {...prev,is_online:value}})
        })
        .catch((error) => { });
    }, 300);
    setOnlineSurvey((prev) => !prev);
  }

  function handleTolerance(value) {
    if (timeout3) {
      clearTimeout(timeout3);
    }
    timeout3 = setTimeout(() => {
      axiosPrivate
        .post(`${URL_PROJECT_EDIT}`, { id: proj?.id, slug:proj?.slug, tolerance: value })
        .then((res) => {
          setProj(prev=>{return {...prev,tolerance:value}})
        })
        .catch((error) => { });
    }, 300);
    setTolerance(value);
  }

  function handleEditWindow(value, is_sup=false) {
    if (timeout4) {
      clearTimeout(timeout4);
    }
    timeout4 = setTimeout(() => {
      let data = { id: proj?.id, slug:proj?.slug, sur_data_edit_window: value }
      if (is_sup) data = { id: proj?.id, slug:proj?.slug, sup_data_edit_window: value }
      axiosPrivate
        .post(`${URL_PROJECT_EDIT}`, data)
        .then((res) => {
          setProj(prev=>{return {...prev, ...data}})
        })
        .catch((error) => { });
    }, 300);
    setTolerance(value);
  }
  function handleEditToggle(is_sup=false) {
    var value = !surEdit
    if (is_sup) value = !supEdit 
    if (timeout5) {
      clearTimeout(timeout5);
    }
    timeout5 = setTimeout(() => {
      let data = { id: proj?.id, slug:proj?.slug, can_sur_edit_approved_data: value }
      if (is_sup) data = { id: proj?.id, slug:proj?.slug, can_sup_edit_approved_data: value }
      axiosPrivate
        .post(`${URL_PROJECT_EDIT}`, data)
        .then((res) => {
          setProj(prev=>{return {...prev, ...data}})
        })
        .catch((error) => { });
    }, 300);
    if(is_sup)
      setSupEdit((prev) => !prev);
    else
      setSurEdit((prev) => !prev);

  }

  if (proj.access_level < 3)
    return (
      <div className="text-md flex-1">
        <div className="flex flex-col">
          <div>
            <h6>
              <strong className="text-l">Your Project</strong>
            </h6>
            <div className="md:grid grid-cols-2 gap-4 mb-4">
              <div className="flex boxes border p-6 justify-between items-center mt-2">
                <div className="grid grid-cols-2 gap-12">
                  <div className="flex flex-col gap-6 text-ms">
                    <h6>Project active</h6>
                    <h6>Map based survey</h6>
                    <h6>Online survey</h6>
                    <h6>Survey tolerance (in meters)</h6>
                  </div>
                  <div className="flex flex-col gap-6 text-ms">
                    <Switch
                      checked={active}
                      onChange={(e) => {
                        handleActiveToggle(e);
                      }}
                      className={`${active ? "bg-slate-700" : "bg-slate-300"}
                    relative inline-flex flex-shrink-0 h-[20px] w-[40px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <span
                        aria-hidden="true"
                        className={`${active ? "translate-x-5" : "translate-x-0"
                          }
          mb-0 pointer-events-none inline-block h-[16px] w-[16px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                      />
                    </Switch>
                    <Switch
                      checked={useMap}
                      onChange={() => {
                        handleUseMapToggle();
                      }}
                      className={`${useMap ? "bg-slate-700" : "bg-slate-300"}
                      relative inline-flex flex-shrink-0 h-[20px] w-[40px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <span
                        aria-hidden="true"
                        className={`${useMap ? "translate-x-5" : "translate-x-0"
                          }
            mb-0 pointer-events-none inline-block h-[16px] w-[16px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                      />
                    </Switch>
                    <Switch
                      checked={onlineSurvey}
                      onChange={() => {
                        handleUseOnlineToggle();
                      }}
                      className={`${onlineSurvey ? "bg-slate-700" : "bg-slate-300"}
                      relative inline-flex flex-shrink-0 h-[20px] w-[40px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <span
                        aria-hidden="true"
                        className={`${onlineSurvey ? "translate-x-5" : "translate-x-0"}
                          mb-0 pointer-events-none inline-block h-[16px] w-[16px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                      />
                    </Switch>

                    <input
                      type="number"
                      name="tolerance"
                      onChange={(e) => handleTolerance(e.target.value)}
                      className="text-md h-6 w-10 bg-transparent rounded-0 border-b-2 border-bottom px-0 m-0 focus:outline-none "
                      placeholder="25"
                      onInput={(e) => ValueValidator(e, 1, 100000)}
                      defaultValue={tolerance}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="flex boxes border p-6 justify-between items-center mt-2">
                <div className="grid grid-cols-2 gap-12">
                  <div className="flex flex-col gap-6 text-ms">
                    <h6>Allow Surveyors to edit approved data</h6>
                    <h6>Allow Supervisor to edit approved data</h6>
                    <h6>Surveyor Data edit window after submission (in days)</h6>
                    <h6>Supervisor Data edit window after submission (in days)</h6>
                  </div>
                  <div className="flex flex-col gap-6 text-ms">
                    <Switch
                      checked={surEdit}
                      onChange={(e) => {
                        handleEditToggle(false);
                      }}
                      className={`${surEdit ? "bg-slate-700" : "bg-slate-300"}
                    relative inline-flex flex-shrink-0 h-[20px] w-[40px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <span
                        aria-hidden="true"
                        className={`${surEdit ? "translate-x-5" : "translate-x-0"
                          }
          mb-0 pointer-events-none inline-block h-[16px] w-[16px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                      />
                    </Switch>
                    <Switch
                      checked={supEdit}
                      onChange={() => {
                        handleEditToggle(true);
                      }}
                      className={`${supEdit ? "bg-slate-700" : "bg-slate-300"}
                      relative inline-flex flex-shrink-0 h-[20px] w-[40px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <span
                        aria-hidden="true"
                        className={`${supEdit ? "translate-x-5" : "translate-x-0"
                          }
            mb-0 pointer-events-none inline-block h-[16px] w-[16px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                      />
                    </Switch>
                    <input
                      type="number"
                      name="sur_edit"
                      onChange={(e) => handleEditWindow(e.target.value, false)}
                      className="text-md h-6 w-10 bg-transparent rounded-0 border-b-2 border-bottom px-0 m-0 focus:outline-none "
                      placeholder="25"
                      onInput={(e) => ValueValidator(e, 1, 1000)}
                      defaultValue={surDataEditTime}
                      required
                    />
                    <input
                      type="number"
                      name="sup_edit"
                      onChange={(e) => handleEditWindow(e.target.value, true)}
                      className="text-md h-6 w-10 bg-transparent rounded-0 border-b-2 border-bottom px-0 m-0 focus:outline-none "
                      placeholder="25"
                      onInput={(e) => ValueValidator(e, 1, 1000)}
                      defaultValue={supDataEditTime}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <span className="w-100 text-center text-sm">
        You are not authorized to access this page. Please ask project admin to
        change the privilage
      </span>
    );
};

export default Settings;
