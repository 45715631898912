import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { IoMdClose } from "react-icons/io";

const Modal = forwardRef(
  (
    {
      heading,
      fields,
      handleSubmit,
      delBtnHandler,
      btnText = "Create",
      closeText = "Close",
      delBtn = null,
      delModal = null,
      width = null,
      closeFun = null,
    },
    ref
  ) => {
    let [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);
    useImperativeHandle(ref, () => {
      return {
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
      };
    });

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={() => {
            try {
              if (closeFun) closeFun();
            } catch (error) {}
            setIsOpen(false);
          }}
          style={{ zIndex: "1050" }}
        >
          <div className="px-4 text-center">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (handleSubmit) {
                  buttonRef.current.disabled = true;
                  handleSubmit(e, buttonRef);
                }
              }}
            >
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-900 opacity-70" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div
                  className="inline-block w-full right-0 p-6 overflow-visible text-left transition-all transform bg-white shadow-xl"
                  style={{ maxWidth: width ? width : "32rem" }}
                >
                  {heading && (
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-slate-900 border-b pb-2"
                    >
                      {heading}
                    </Dialog.Title>
                  )}
                  <IoMdClose
                    className="absolute right-2 top-2 cursor-pointer text-xl"
                    onClick={() => setIsOpen(false)}
                  />
                  <div className="mt-4">{fields}</div>
                  <div
                    className={`flex gap-4 ${
                      delBtn ? "justify-between" : "justify-end"
                    }`}
                  >
                    {delBtn && (
                      <button
                        ref={buttonRef}
                        type="button"
                        className="button-red"
                        onClick={(e)=>{
                          buttonRef.current.disabled = true;
                          delBtnHandler(e, buttonRef)}
                        }
                      >
                        {delBtn}
                      </button>
                    )}
                    <div className="flex gap-4 justify-end">
                      {btnText && (
                        <button
                          ref={buttonRef}
                          type="submit"
                          className="button bg-slate-800 text-white"
                        >
                          {btnText}
                        </button>
                      )}
                      {closeText && (
                        <button
                          type="button"
                          className="button bg-slate-200"
                          onClick={() => {
                            try {
                              if (closeFun) closeFun();
                            } catch (error) {}
                            setIsOpen(false);
                          }}
                        >
                          {closeText}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </form>
            {delModal}
          </div>
        </Dialog>
      </Transition>
    );
  }
);

export default Modal;
