import { GetMaxValueValidator, GetValidator } from "components/common/Validators";
import { memo, useEffect, useState } from "react";

const InputDefault = ({ ques, def, is_allowed, handler = null }) => {
  const [val, setVal] = useState(def)

  useEffect(() => {
    setVal(def)
  }, [def])
  return (
    <div className="mb-4">
      <input
        className={`input mb-0`}
        defaultValue={def}
        onKeyPress={(e) => {
          if (is_allowed) GetValidator(e, ques.q_type);
        }}
        value={val}
        onInput={(e) => {
          GetMaxValueValidator(e, ques.q_type, ques.minimum, ques.maximum);
        }}
        onChange={(e) => setVal(e.target.value)}
        onBlur={(e) => {
          if (handler && is_allowed) handler(e.target.value);
        }}
        disabled={!is_allowed}
      />
    </div>
  );
};

export default InputDefault;

function propsAreEqual(prev, next) {
  return prev.ques === next.ques && prev.def === next.def;
}

export const MemoInputDefault = memo(InputDefault, propsAreEqual);

