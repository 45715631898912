import {
  IoDocumentTextSharp,
  IoPeople,
  IoMapSharp,
  IoSettings,
  IoSettingsSharp,
} from "react-icons/io5";
import { ImHome3, ImParagraphLeft } from "react-icons/im";
import {
  AiFillCaretLeft,
  AiFillCaretRight,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";
import GeoditLogo from "./GeoditLogo";
import { useLocation, useNavigate } from "react-router";

const itemList = [
  {
    id: "overview-tab",
    title: "Project Overview",
    img: <ImHome3 className={`text-lg pointer text-white`} />,
    alt: "overview",
    sep: false,
  },
  { id: "sep-1", title: "Survey", sep: true },
  {
    id: "team-tab",
    title: "Team",
    img: <IoPeople className={`text-lg pointer text-white`} />,
    alt: "team",
    sep: false,
  },
  {
    id: "map-tab",
    title: "Map",
    img: <IoMapSharp className={`text-lg pointer text-white`} />,
    alt: "map",
    sep: false,
  },
  {
    id: "form-tab",
    title: "Forms",
    img: <IoDocumentTextSharp className={`text-lg pointer text-white`} />,
    alt: "forms",
    sep: false,
  },
  {
    id: "data-tab",
    title: "Data",
    img: <ImParagraphLeft className={`text-md pointer text-white mt-1`} />,
    alt: "data",
    sep: false,
  },
  { id: "sep-3", title: "Settings", sep: true },
  {
    id: "settings-tab",
    title: "Settings",
    img: <IoSettingsSharp className={`text-lg pointer text-white mt-[1px]`} />,
    alt: "settings",
    sep: false,
  },
];
const Sidebar = ({ miniSidebar, setMiniSidebar, proj = null }) => {
  // const ctx = useLayoutContext();
  // const proj = ctx?.proj;
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  // const [cookies] = useCookies(["proj"]);
  // const proj = cookies.proj;
  return (
    <div
      className={`fixed top-0 h-screen bg-slate-900 min-h-0 ${miniSidebar ? "w-16" : "w-52"
        }`}
      style={{ zIndex: 1030 }}
    >
      <ul id="side-tabs">
        <li
          className={`side-item bg-slate-900 flex h-14 items-center pl-2`}
          onClick={() => navigate(`/dashboard`)}
        >
          <GeoditLogo miniSidebar={miniSidebar} white={true} />
        </li>
        {itemList.map((item, index) => {
          let active = false;
          if (pathname.includes(item.alt)) {
            active = true;
          }
          let show = true;
          // if (item.id === "map-tab") {
          //   show = proj?.show_map;
          // }
          if (show)
            if (!item.sep) {
              return (
                <li
                  key={index}
                  id={item.id}
                  className={`side-item ${active ? "bg-slate-700" : "bg-slate-900"
                    }`}
                  onClick={() => navigate(`/${proj?.slug}/${item.alt}`)}
                >
                  <div
                    className={`flex justify-start align-middle p-4 ${miniSidebar && "ml-1"
                      }`}
                  >
                    {item.img}
                    {!miniSidebar && (
                      <span className={`ml-4 text-md`}>{item.title}</span>
                    )}
                  </div>
                </li>
              );
            } else {
              return (
                <div key={item.id}>
                  {miniSidebar ? (
                    ""
                  ) : (
                    <li
                      className={`bg-slate-800 border-slate-700 border-b px-4 py-2`}
                    >
                      <small>{item.title}</small>
                    </li>
                  )}
                </div>
              );
            }
        })}
      </ul>
      <div className="w-100 mt-8 hidden md:block">
        {miniSidebar ? (
          <AiOutlineRight
            className="text-white text-2xl mr-6 mt-4 cursor-pointer float-right"
            onClick={() => setMiniSidebar(false)}
          />
        ) : (
          <AiOutlineLeft
            className="text-white text-2xl mr-4 mt-4 cursor-pointer float-right"
            onClick={() => setMiniSidebar(true)}
          />
        )}
      </div>
    </div>
  );
};

export default Sidebar;
