import { useEffect, useState } from "react";
import useRefreshToken from "hooks/useRefreshToken";
import useAuth from "hooks/useAuth";
import { Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";


const Layout = () => {

  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth, persist } = useAuth();

  const [cookie] = useCookies('refresh');

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      }
      catch (err) {
        // console.error(err);
      }
      finally {
        isMounted && setIsLoading(false);
      }
    }
    cookie?.refresh && !auth?.access && persist ? verifyRefreshToken() : setIsLoading(false);
    return () => isMounted = false;
  }, [])

  return (
    <>
      {!persist
        ? <Outlet />
        : isLoading
          ? <p>Loading...</p>
          : <Outlet />
      }
    </>
  );
};

export default Layout;
