import { useNonInitialEffect } from "hooks/useNonInitialEffect";
import { memo, useState } from "react";

const InputMultiChoice = ({ ques, def, is_allowed, handler = null }) => {
  if (!def && def == "") def = "[]";
  let l = [];
  try {
    l = JSON.parse(def);
  } catch {
    l = [];
  }
  if (!l) l = [];
  const [val, setVal] = useState(l);
  useNonInitialEffect(() => {
    handler(JSON.stringify(val));
    return () => {};
  }, [val]);
  return (
    <div className="mb-4">
      <div className="flex flex-col justify-start">
        {ques.opt_list.map((o) => {
          let checked = "";
          if (val?.includes(o.value)) checked = "checked";
          return (
            <label
              key={o.id}
              className="inline-flex items-center checkbox-container mb-1"
            >
              {o.value}
              <input
                name={ques.id}
                type="checkbox"
                id={o.id}
                value={o.value}
                className="peer hidden"
                defaultChecked={checked}
                disabled={!is_allowed}
                onChange={(e) => {
                  if (is_allowed) {
                    let value = e.target.value;
                    let isChecked = e.target.checked;
                    setVal((prev) => {
                      if (!prev.includes(value) && isChecked) {
                        return [...prev, value];
                      } else if (prev.includes(value) && !isChecked) {
                        return prev.filter((item) => item !== value);
                      }
                    });
                  }
                }}
              />
              <span className="checkbox peer-checked:bg-blue-500 peer-checked:after:block"></span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default InputMultiChoice;

function propsAreEqual(prev, next) {
  return prev.ques === next.ques && prev.def === next.def;
}

export const MemoInputMultiChoice = memo(InputMultiChoice, propsAreEqual);
