import { useContext, useEffect, useRef, useState } from "react";
import { FullScreen, defaults as defaultControls } from "ol/control";
import { Map as OlMap } from "ol";

import View from "ol/View";
import { useGeographic } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { ResponsesContext } from "pages/Responses";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import MVT from "ol/format/MVT";
import { Feature } from "ol";
import LayerStyle from "components/map/components/LayerStyle";
import { useNonInitialEffect } from "hooks/useNonInitialEffect";
import { URL_VT } from "constants";
import useAuth from "hooks/useAuth";

const BASE_URL = process.env.REACT_APP_API_PATH;
const Map = ({ setDownloadOpt }) => {
  const ctx = useContext(ResponsesContext);
  const { auth } = useAuth()

  const bbox = ctx?.state?.bbox;
  const proj = ctx?.proj
  const surArea = { ...ctx?.state?.surArea, visible: true, sa: true };
  const mapRef = useRef();
  const [tileLyr, setTileLyr] = useState(undefined);
  const [selectedFeat, setSelectedFeat] = useState([]);

  useGeographic();
  useEffect(() => {
    const fs = defaultControls().extend([new FullScreen()]);
    let options = {
      view: new View({
        center: [74.028, 30.4036],
        zoom: 16,
        maxZoom: 19,
      }),
      layers: [],
      controls: fs,
      overlays: [],
    };
    let map = new OlMap(options);
    map.setTarget(mapRef.current);

    const osmBasemap = new TileLayer({
      source: new XYZ({
        url: "https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",
      }),
    });
    map.addLayer(osmBasemap);
    let saData = `${surArea.id}-${surArea.lbl_field}`;

    const url = `${BASE_URL}${URL_VT}/${proj?.id}/{z}/{x}/{y}?sur_area=${saData}&shp=${[]}`;

    const getTile = (url, tile, extent, projection) => {
      fetch(url, {
        headers: {
          Authorization: `JWT ${auth?.access}`,
        },
      }).then(function (response) {
        response.arrayBuffer().then(function (data) {
          const format = tile.getFormat();
          const features = format.readFeatures(data, {
            extent: extent,
            featureProjection: projection,
          });
          tile.setFeatures(features);
        });
      });
    };
    const vector = new VectorTileLayer({
      zIndex: 10,
      renderMode: "vector",
      source: new VectorTileSource({
        overlaps: false,
        format: new MVT({
          featureClass: Feature,
        }),
        tileSize: 4096,
        url,
        tileLoadFunction: (tile, url) => {
          tile.setLoader(function (extent, _resolution, projection) {
            getTile(url, tile, extent, projection);
          });
        },
      }),
      declutter: true,
    });
    map.addLayer(vector);
    setTileLyr(vector);

    function clickEvent(event) {
      map?.forEachFeatureAtPixel(event.pixel, function (feature) {
        let id = feature.getId();
        setSelectedFeat((prev) => {
          let li = [...prev];

          if (li.includes(id)) {
            const index1 = li.indexOf(id);
            if (index1 > -1) {
              li.splice(index1, 1);
            }
          } else {
            li.push(id);
          }
          return li;
        });
      });
    }
    map.on("click", clickEvent);

    if (bbox) map.getView().fit(bbox, map.getSize());
    return () => {
      map.un("click", clickEvent);
      map.setTarget(undefined);
    };
  }, []);

  useNonInitialEffect(() => {
    if (tileLyr) {
      tileLyr?.setStyle((f, r) => {
        if (!f.getId()) f.setId(f.get("id"));
        let select = false;

        selectedFeat?.forEach((v) => {
          if (v?.includes(f.getId())) {
            select = true;
          }
        });
        return LayerStyle(f, r, surArea, select);
      });
      setDownloadOpt((prev) => ({ ...prev, sa_list: selectedFeat }));
    }
    return () => { };
  }, [tileLyr, selectedFeat]);

  return (
    <div className="border border-bottom-0 mt-4">
      <div id="map-div" ref={mapRef} style={{ height: "360px" }}></div>
    </div>
  );
};

export default Map;
