import { memo } from "react";
import { GetMaxValueValidator, GetValidator } from "components/common/Validators";

const InputPara = ({ ques, def, is_allowed, handler = null }) => {
  return (
    <div className="mb-4">
      <textarea
        className="input h-20 mb-0"
        defaultValue={def}
        onKeyPress={(e) => {
          if (is_allowed) GetValidator(e, ques.q_type);
        }}
        onInput={(e) => {
          GetMaxValueValidator(e, ques.q_type, 1, 2000);
        }}
        style={{ resize: "none" }}
        onBlur={(e) => {
          if (handler && is_allowed) handler(e.target.value);
        }}
        disabled={!is_allowed}
      />
    </div>
  );
};

export default InputPara;

function propsAreEqual(prev, next) {
  return prev.ques === next.ques && prev.def === next.def;
}

export const MemoInputPara = memo(InputPara, propsAreEqual);
