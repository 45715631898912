import { axiosClient } from "api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
const BASE_URL = process.env.REACT_APP_API_PATH;

const useAxiosClient = (url=null) => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {

        const requestIntercept = axiosClient.interceptors.request.use(
            config => {
                config.baseURL =  url
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `JWT ${auth?.access}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosClient.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `JWT ${newAccessToken}`;
                    return axiosClient(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosClient.interceptors.request.eject(requestIntercept);
            axiosClient.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh, url])

    return axiosClient;
}

export default useAxiosClient;