import Charts from "components/overview/Charts";
import FormatDigits from "components/layout/header/FormatDigits";
import useAuth from "hooks/useAuth";
import axios from "api/axios";
import useSWR from "swr";
import { URL_OVERVIEW, URL_OVERVIEW_ANS_COUNT } from "constants";
import useBase from "hooks/useBase";
import { useEffect } from "react";

const fetcher = async (url, access) => {
  const response = await axios.get(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `JWT ${access}`,
    },
  });
  return response?.data?.data;
};

const fetcherCount = async (url, access) => {
  const response = await axios.get(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `JWT ${access}`,
    },
  });
  return response?.data?.data;
};

function Overview() {
  const { auth } = useAuth();
  const { proj, plan, apiUrl } = useBase();
  const { data } = useSWR("overview", () => fetcher(`${URL_OVERVIEW}${proj?.id}`, auth?.access));
  let url = `${URL_OVERVIEW_ANS_COUNT}/${proj?.id}`;
  if(apiUrl) url =  apiUrl+url;
  const data_count = useSWR("overview_count", () => fetcherCount(url, auth?.access));
  let textColor = "";
  let currPlan = "Demo"
  let pd = plan?.details;
  let formCount = 0;
  if (pd?.type == "unlimited"){
    currPlan = `${pd?.id} - Unlimited forms for ${pd?.validity
    } days.`
  }
  else {
    if(pd?.id) {
      currPlan = `${pd?.id} - ${FormatDigits(pd?.forms)} forms for ${pd?.validity
      } days.`
    }
    formCount = plan?.forms 
    if (formCount < 1) {
      textColor = "text-red-500";
    } else if (formCount < 50) {
      textColor = "text-yellow-500";
    }
}
  useEffect(() => {
    document.title = "Geodit | Overview";
  }, []);

  return (
    <div className="text-md flex-1">
      <div className="grid grid-cols-1">
        <div className="sm:flex boxes mt-0 border p-6 justify-between items-center">
          <div className="gap-12 mb-2 sm:mb-0">
            <div className="sm:flex gap-12 mb-2">
              <h6 className="text-sm font-bold	">Current plan</h6>
              <h6 className="text-xs sm:text:sm">
                  {currPlan}
              </h6>
            </div>
            <div className="sm:flex gap-8 flex-1">
              <h6 className={`text-sm ${textColor} font-bold`}>Responses left</h6>
              
              <h6 className={`text-xs sm:text:sm  ${textColor}`}>
                {pd?.type == "unlimited" ? "Unlimited" :formCount ? FormatDigits(formCount) : "0"}
              </h6>
            </div>
          </div>
          <div className="sm:flex gap-8 mr-0  items-center">
            <h6 className="text-sm font-bold">
              Valid till
              </h6>
              <h6 className="font-normal text-xs sm:text:sm">
                {plan ? plan.expires_on : "NA"}
              </h6>
          </div>
        </div>
      </div>
      <div className="lg:grid lg:grid-cols-3 gap-3 space-y-4 lg:space-y-0">
        <div className="boxes my-0 border p-6">
          <h6>Data Volume</h6>
          <h2 className="text-3xl">
            <strong>{data_count?.data?.count ? data_count?.data?.count : "0"}</strong>
          </h2>
        </div>
        <div className="boxes border p-6">
          <h6>Number of Surveyors</h6>
          <h2 className="text-3xl">
            <strong>{data ? data.sur_count : "0"}</strong>
          </h2>
        </div>
        <div className="boxes border p-6">
          <h6>Number of Forms</h6>
          <h2 className="text-3xl">
            <strong>
              {data ? data.form_list && data.form_list.length : "0"}
            </strong>
          </h2>
        </div>
      </div>
      <div className="grid grid-cols-1 mt-8">
        <div>
          <h6 className="mb-2">
            <strong className="text-l">Data Insights</strong>
          </h6>
          <Charts
            formList={data?.form_list}
          />
        </div>
      </div>
    </div>
  );
}

export default Overview;
