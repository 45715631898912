import { memo, useState } from "react";
import ReactStarsRating from "react-awesome-stars-rating";

const InputRating = ({ ques, def, is_allowed, handler = null }) => {
  let defVal = 0;
  let defCount = 5;
  try {
    defVal = parseInt(def);
  } catch {}
  try {
    defCount = parseInt(ques?.maximum);
  } catch {}

  const [rating, setRating] = useState(defVal);
  return (
    <div className="mb-4">
      <ReactStarsRating
        className="flex"
        onChange={(r) => {
          if (is_allowed) {
            setRating(r);
            if (handler) handler(r);
          }
        }}
        value={rating}
        count={defCount}
        isHalf={false}
      />
    </div>
  );
};

export default InputRating;

function propsAreEqual(prev, next) {
  return prev.ques === next.ques && prev.def === next.def;
}

export const MemoInputRating = memo(InputRating, propsAreEqual);
