import { useEffect, useState, useRef } from "react";
import { BiCalendar } from "react-icons/bi";
import { DateRangePicker } from "react-date-range";
import { format, subDays } from "date-fns";

const DatePicker = ({ onChange, text = "text-sm", w = "w-100", days = 29 }) => {
  const [showDate, setShowDate] = useState(false);
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), days),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (ref.current && !ref.current.contains(e.target)) {
        setShowDate(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  let startDate = format(state[0].startDate, "yyyy/MM/dd");
  let endDate = format(state[0].endDate, "yyyy/MM/dd");
  return (
    <div
      className={`border br-1 px-3 py-1 cursor-pointer flex items-center bg-white justify-between gap-1 h-9 ${w}`}
      ref={ref}
    >
      <BiCalendar
        className="text-yellow-900"
        style={{
          fontSize: "20px",
          paddingTop: "2px",
          paddingBottom: "2px",
        }}
      />
      {showDate && (
        <div className="relative">
          <DateRangePicker
            className="absolute z-50 top-4"
            rangeColors={["#eab308"]}
            onChange={(item) =>
              setState((prev) => {
                onChange(item.selection.startDate, item.selection.endDate);
                return [item.selection];
              })
            }
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
          />
        </div>
      )}
      <span
        className={`${text} text-right`}
        onClick={() => {
          setShowDate((prev) => !prev);
        }}
      >
        {startDate} - {endDate}
      </span>
    </div>
  );
};

export default DatePicker;
