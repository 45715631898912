import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import Circle from "ol/style/Circle";
import Text from "ol/style/Text";
import { asArray } from "ol/color";
import CircleStyle from "ol/style/Circle";

// const addAlpha = (color, opacity) => {
//   var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
//   return color + _opacity.toString(16).toUpperCase();
// };
const font = "bold 10px Arial";

export default function LayerStyle(
  feature,
  resolution,
  layerInfo,
  select = false
) {
  let style;
  const geom = layerInfo?.g_type?.toLowerCase();
  const white = [255, 255, 255, 1];
  const boundary = [0, 0, 0, 1];
  const width = 5;
  const ans = feature.get("ans_id");
  const completed = feature.get("completed");
  if (!layerInfo?.visible) return null;
  let isDeleted = feature.get("deleted");
  
  if (feature.get("deleted") && layerInfo.symbology_type !== "editing")
    return null;
  // if (geom?.includes("line")) {
  //   if (resolution > 20) return null;
  // } else if (resolution < 20) return null;
  let ge = "poly";
  function getColor() {
    let color = "rgba(255, 165, 0, 0.3)";
    // let deleted = "rgba(255, 0, 0, 0.3)";
    if (select) color = [252, 233, 79, 0.9];
    else if (layerInfo?.symbology) {
      let obj = layerInfo.symbology;

      if (layerInfo.sa || layerInfo.symbology_type === "single") {
        color = obj[Object.keys(obj)[0]];
      } else if (layerInfo.symbology_type === "editing") {
        if (feature.get("deleted")) color = obj["Deleted"];
        else if (feature.get("created")) color = obj["Created"];
        else if (feature.get("edited")) color = obj["Edited"];
        else color = obj["Default"];
      } else if (layerInfo.symbology_type === "data") {
        if (ans) color = obj["Collected"];
        else color = obj["Default"];
      } else if (layerInfo.symbology_type === "status") {
        color = obj["Unchecked"];
        let sym = feature.get("symbology");
        if (sym in obj) color = obj[sym];
      } else if (layerInfo.symbology_type === "unique") {
        color = obj["all other values ..."];
        let sym = feature.get("symbology");
        if (sym in obj) color = obj[sym];
      }
    }
    if (ans || completed) {
      color = asArray(color);
      color[3] = 0.8;
    }
    return color;
  }

  let sur = feature.get("sur");
  let label = feature.get("label");
  let showSur = false;
  if (sur && sur !== "" && sur !== "undefined" && sur !== "nan") {
    showSur = true;
  }
  let showLbl = false;
  if (label && label !== "" && label !== "undefined" && label !== "nan") {
    showLbl = true;
  }

  if (geom?.includes("point")) ge = "point";
  else if (geom?.includes("line")) ge = "line";
  switch (ge) {
    case "point":
      style = [
        new Style({
          image: new CircleStyle({
            stroke: new Stroke({
              color: "#ffffff",
            }),
            fill: new Fill({
              color: getColor(),
            }),
            radius: 15,
          }),
          text: layerInfo?.lbl_type
            ? new Text({
              font: font,
              fill: new Fill({
                color: layerInfo?.lbl_color,
              }),
              overflow: true,
              text: `${showLbl ? label : ""} ${isDeleted ? "\n" + "deleted" : ""
                }`,
              stroke: new Stroke({ color: "#000000", width: 3 }),
              offsetX: 10,
              offsetY: 10,

            })
            : undefined,
          zIndex: select?Infinity:100 - layerInfo?.position,
        }),
      ];
      break;
    case "line":
      style = [
        new Style({
          stroke: new Stroke({
            color: getColor(),
            width: 6,
          }),
          text: layerInfo?.lbl_type
            ? new Text({
              font: font,
              fill: new Fill({
                color: layerInfo?.lbl_color,
              }),
              overflow: true,
              text: `${showLbl ? label : ""} ${isDeleted ? "\n" + "deleted" : ""
                }`,
              stroke: new Stroke({ color: "#000000", width: 3 }),
            })
            : undefined,
          zIndex: select?Infinity:100 - layerInfo?.position,
        }),
      ];
      break;
    case "poly":
      style = [
        new Style({
          stroke: new Stroke({
            color: "#ffffff",
          }),
          fill: new Fill({
            color: getColor(),
          }),
          text: layerInfo?.lbl_type
            ? new Text({
              font: font,
              fill: new Fill({
                color: layerInfo?.lbl_color,
              }),
              overflow: true,
              text: `${showLbl ? label : ""}  ${isDeleted ? "\n" + "deleted" : ""
                } `,
              stroke: new Stroke({ color: "#000000", width: 3 }),
            })
            : layerInfo?.sa
              ? new Text({
                font: font,
                fill: new Fill({
                  color: layerInfo?.lbl_color,
                }),
                overflow: true,
                text: `${showLbl ? label : ""} ${showSur ? "\n" + sur : ""}`,
                stroke: new Stroke({ color: "#000000", width: 3 }),
              })
              : undefined,
          zIndex: select?Infinity:100 - layerInfo?.position,
        }),
      ];
      break;

    default:
      break;
  }

  if (select) {
    switch (ge) {
      case "point":
        style = [
          new Style({
            image: new CircleStyle({
              radius: 15,
              stroke: new Stroke({
                color: boundary,
                width: width / 2,
              }),
              fill: new Fill({
                color: getColor(),
              }),
            }),
            text: layerInfo?.lbl_type
            ? new Text({
              font: font,
              fill: new Fill({
                color: layerInfo?.lbl_color,
              }),
              overflow: true,
              text: `${showLbl ? label : ""} ${isDeleted ? "\n" + "deleted" : ""
                }`,
              stroke: new Stroke({ color: "#000000", width: 3 }),
              offsetX: 10,
              offsetY: 10,

            })
            : undefined,
            zIndex: Infinity,
          }),
        ];
        break;
      default:
        style = [
          new Style({
            stroke: new Stroke({
              color: white,
              width: width + 1,
            }),
          }),
          new Style({
            stroke: new Stroke({
              color: boundary,
              width: width,
            }),
          }),
          ...style,
        ];
        break;
    }
  }

  return style;
}
