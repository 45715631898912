import { useState, useImperativeHandle, forwardRef } from "react";
const Save = forwardRef((props, ref) => {
  const [state, setState] = useState("");
  const prefix = props?.prefix
  useImperativeHandle(ref, () => {
    return {
      saving: () => setState(" - saving ..."),
      saved: () => setState(" - saved"),
      error: () => setState(" - error while saving"),
    };
  });
  return <span>{prefix}{state}</span>;
});

export default Save;
