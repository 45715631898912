import { memo, useRef, useState } from "react";
import { AiFillCamera } from "react-icons/ai";
import imageCompression from "browser-image-compression";

const InputImage = ({ ques, def, is_allowed, handler = null }) => {
  const [val, setVal] = useState(def);
  let imageRef = useRef(null);

  async function handleImgChange(e) {
    let files = e.target.files;
    if (files.length > 0) {
      let img = files[0];
      const options = {
        maxSizeMB: 0.7,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      let compressedFile = await imageCompression(img, options);
      if (compressedFile) {
        setVal(URL.createObjectURL(compressedFile));
        handler(compressedFile);
      }
    }
  }

  return (
    <div className="mb-4">
      <div className="flex flex-col justify-start">
        <input
          ref={imageRef}
          type="file"
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleImgChange}
        />
        {val ? (
          <div
            className="border relative"
            style={{ height: "175px", width: "175px" }}
          >
            {is_allowed && (
              <AiFillCamera
                title="Change Image"
                className="text-2xl cursor-pointer p-1 absolute bg-white"
                onClick={() => imageRef.current.click()}
                style={{ right: 0 }}
              />
            )}
            <img
              className="p-2 float-start"
              src={val}
              alt={val && "Invalid Image"}
              style={{ height: "175px", width: "175px" }}
              onClick={() => window.open(val)}
            />
          </div>
        ) : (
          <div
            onClick={() => {
              if (is_allowed) imageRef.current.click();
            }}
            className="border p-2 flex align-muddle justify-center cursor-pointer"
            style={{ height: "175px", width: "175px" }}
          >
            {is_allowed && "Click to upload image"}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputImage;

function propsAreEqual(prev, next) {
  return prev.ques === next.ques && prev.def === next.def;
}

export const MemoInputImage = memo(InputImage, propsAreEqual);
