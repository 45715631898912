import { STATIC_URL } from "constants";
import { useNavigate } from "react-router";

const GeoditLogo = ({ miniSidebar, white = false }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`flex items-center justify-start cursor-pointer ${miniSidebar ? "pl-2" : white ? "pl-1" : "pl-4"
        }`}
      onClick={() => {
        navigate("/dashboard");
      }}
    >
      {/* <Image
          src={STATIC_URL + "images/geodit.png"}
          alt="logo"
          height="30px"
          width="30px"
        /> */}
      <div className="flex justify-center">
        <img
          style={{ height: "2.0rem" }}
          src={
            white
              ? STATIC_URL + "images/geodit_white.png"
              : STATIC_URL + "images/geodit.png"
          }
          alt="logo"
        />
      </div>
      {!miniSidebar && (
        <span
          className={`pl-1 font-normal ${white ? "text-white" : "text-slate-700"
            } text-lg`}
        >
          eodit
        </span>
      )}
    </div>
  );
};

export default GeoditLogo;
