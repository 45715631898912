import Dropdown from "components/common/Dropdown";
import { memo } from "react";

const InputDropdown = ({ ques, def, is_allowed, handler = null }) => {
  let opt = [];
  let defVal = { label: "Select", value: "" };
  ques.opt_list.map((o) => {
    if (o.value == def) defVal = { label: o.value, value: o.value, id: o.id };
    opt.push({ label: o.value, value: o.value, id: o.id });
  });
  return (
    <div className="mb-4">
      <Dropdown
        opt={opt}
        def={defVal}
        text="text-xs"
        w="w-full"
        handler={(e) => {
          if (handler && is_allowed) handler(e.value);
        }}
        disabled={!is_allowed}
      />
    </div>
  );
};

export default InputDropdown;
function propsAreEqual(prev, next) {
  return prev.ques === next.ques && prev.def === next.def;
}

export const MemoInputDropdown = memo(InputDropdown, propsAreEqual);
