import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";

import { MaxChar} from "components/common/Validators";
import { URL_DATA_LIST } from "constants";
import { IoCaretBackSharp, IoCaretForwardSharp } from "react-icons/io5";
import TableRow from "./TableRow";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useBase from "hooks/useBase";
import AnsModal from "components/AnsModal";
import Dropdown from "components/common/Dropdown";
import { ResponsesContext } from "pages/Responses";
import { MdAdd } from "react-icons/md";
import { useCookies } from "react-cookie";

const defSearchOpt = [
  { value: "id", label: "Id", type:"default" },
  { value: "surveyor", label: "Surveyor", type:"default" },
  { value: "edited_on", label: "Edited On", type:"default" },
  { value: "verified_on", label: "Verified On", type:"default" },
  { value: "verified_by", label: "Verified By", type:"default" },
  { value: "status", label: "Status", type:"default" }
]

const searchTypeOpt = [
  { value: "equals", label: "Equals To"},
  { value: "start", label: "Starts With"},
  { value: "end", label: "Ends With"},
  // { value: "empty", label: "Is Empty"},
]

let timeout;
const Table = () => {
  const axiosPrivate = useAxiosPrivate()
  const { proj } = useBase()

  const refAns = useRef(null);
  const [data, setData] = useState([]);
  const [prev, setPrev] = useState(null);
  const [next, setNext] = useState(null);
  const [search, setSearch] = useState("");
  const [selForm, setSelForm] = useState();
  const [count, setCount] = useState(0);
  const [uniqueQues, setUniqueQues] = useState();
  const [searchType, setSearchType] = useState(searchTypeOpt[0]);
  const [searchQuesOpt, setSearchQuesOpt] = useState(defSearchOpt);
  const [searchQues, setSearchQues] = useState(defSearchOpt[0]);
  const [filterList, setFilterList] = useState([]);
  const ctx = useContext(ResponsesContext);
  const state = ctx?.state
  const errorRef = useRef();
  const [cookies, _, removeCookie] = useCookies("user");

  const fetcher = async (params = {}, url=null) => {
    if(url)
      await axiosPrivate
      .get(url+`&count=${count}`)
      .then((res) => {
        let data = res?.data;
        setData(data?.data);
        setPrev(data?.prev)
        setNext(data?.next)
      })
    .catch((error) => { });
    else
      await axiosPrivate
        .get(`${URL_DATA_LIST}/${proj?.id}`, {
          params: { p: proj?.id, ...params }
        })
        .then((res) => {
          let data = res?.data;
          if(data?.other?.count)
            setCount(data?.other?.count)
            setData(data?.data);
            setPrev(data?.prev)
            setNext(data?.next)
        })
        .catch((error) => { });
  };

  useEffect(() => {
    setSelForm(state?.filterFormList[0])
  }, [state?.filterFormList])
  
  useEffect(() => {
    let optList = [...defSearchOpt]
    let unique_ques = {}
    const filtered = state?.forms.filter(form => {
      return form.id === selForm?.value;
    });
    if (filtered.length > 0) {
      filtered[0]?.page_list?.forEach(page => {
        optList.push({ type: "br", label: page?.name })
        page?.ques_list?.forEach(ques => {
          if (ques?.id == filtered[0]?.unique && ques?.title) {
            unique_ques = ques
            optList.splice(1, 0, { value: "unique", label: ques?.title });
          }
          optList.push({ label: ques?.question ? ques?.question : ques?.title, value: ques.id, type: "ques" })
        })
      });
    }
    setUniqueQues(unique_ques)
    setSearchQuesOpt(optList)
  }, [state?.forms, selForm])

  useEffect(() => {
    if (selForm) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        let params = { form_id: selForm?.value, q: JSON.stringify(filterList) }
        fetcher(params);
      },500);
    }
    return () => { };
  }, [selForm, filterList]);

  const handleAddClick = () =>{
    if((search =="" || !search) && searchType.value !== "empty") {
      errorRef.current.innerText="Search field cannot be empty."
      errorRef.current.classList.remove("hidden");
    } else if(filterList.length>4){
      errorRef.current.innerText="Upto 5 filters are allowed."
      errorRef.current.classList.remove("hidden");
    } else {
      errorRef.current.classList.add("hidden");
      setFilterList(prev=>{
        return [...prev.filter(f => f.ques != searchQues?.value), {ques: searchQues?.value, type:searchQues?.type, label: searchQues?.label, value: search, matcher: searchType?.value}]
      })
      setCount(0)
    }
  }

  const removeAns = useCallback((id) => {
    setData((prev) => {
      return prev.filter((d) => d.id !== id);
    });
  }, []);
  return (
    <div className={`boxes max-h-full my-0 `}>

      <div className="flex flex-col gap-2 h-full">
        <div className="grid grid-cols-8  gap-4 justify-items-start">
          <div className="flex w-72 col-span-3 my-auto ">
            <span className="input-text mb-0 h-9">Form</span>

            <Dropdown
              opt={state.filterFormList}
              def={selForm}
              text="text-xs"
              h=" h-9 py-0"
              w="flex-1"
              handler={(e) => {
                setSelForm(e)
                setCount(0)
              }
              }
            />
          </div>
          <div className="my-auto w-full grid grid-cols col-span-2 justify-items-center">
            <div className="flex my-auto text-sm gap-4">
              <span className={`my-auto mr-1 flex border px-4 py-1 pl-1 ${prev?"hover:bg-slate-200":"bg-gray-100 text-slate-400"} hover:cursor-pointer gap-2`} onClick={()=>{
                if(prev)
                fetcher({}, prev)
              }}>
                <IoCaretBackSharp
                  className="my-auto  cursor-pointer text-lg"
                />

              <span>Prev</span>
              </span>
              <span className={`my-auto mr-1 flex border px-4 py-1 pr-1 hover:cursor-pointer ${next?"hover:bg-slate-200":"bg-gray-100 text-slate-400"} gap-2`}  onClick={()=>{
                if(next)
                fetcher({}, next)
              }}>
              <span>Next</span>
              <IoCaretForwardSharp
                className="my-auto cursor-pointer text-lg"
              />
              
              </span>
            </div>
            <span className="my-auto mt-2 flex-1 text-sm">Total Data - {count}</span>

          </div>
          <div className="my-auto w-full grid grid-cols-3 col-span-3 justify-items-end">
            <div className="my-auto col-span-3 flex">
              <Dropdown
                opt={searchQuesOpt}
                def={searchQues}
                text="text-xs"
                h=" h-9 py-0"
                w="w-24"
                handler={(e) => {
                  setSearchQues(prev => {
                    if (prev !== e) {
                      return e
                    }
                    return prev
                  })
                }
                }
              />
              <Dropdown
                  opt={searchTypeOpt}
                  def={searchType}
                  text="text-xs"
                  h=" h-9 py-0"
                  w="w-28"
                  handler={(e) => {
                    setSearchType(prev => {
                      if(e.value=="empty")
                        setSearch("")
                      if (prev !== e) {
                        return e
                      }
                      return prev
                    })
                  }
                  }
                />
              <div className="flex-grow relative">
              <input
                className="input mb-0 w-72 border-l-0"
                type="text"
                name="name"
                id="name"
                placeholder="Search here"
                required
                onInput={(e) => {
                  MaxChar(e, 250);
                }}
                value={search}
                disabled={searchType.value=="empty"}
                onChange={(e) => {
                  let value = e.target.value;
                  setSearch(value);
                }}
              />
                <div ref={errorRef} className="invalid-tooltip z-50 hidden">
                    Search field cannot be empty.
                  </div>
                  </div>
                <span
                  onClick={() => handleAddClick()}
                  className="input-text bg-teal-500 text-white cursor-pointer text-lg px-2 mb-0"
                >
                  <MdAdd
                    title="Add Option"
                    className={`text-lg cursor-pointer`}
                  />
                </span>
            </div>
          </div>
        </div>
        <div className="text-sm flex gap-4">
          {
            filterList?.map((k)=>{
              let bg="bg-slate-300"
              if(k?.matcher=="start")bg="bg-sky-200" 
              if(k?.matcher=="end")bg="bg-green-200" 
              if(k?.matcher=="empty")bg="bg-red-100" 
              return <span key={k.ques} className={`${bg} text-slate-600 pl-4 py-1 rounded-xl cursor-pointer`}>
              {`${k.label} - ${k.value}`} 
              <span className="ml-2 bg-red-400 px-2  py-1 rounded-xl text-white cursor-pointer"
                onClick={()=>{
                  setFilterList(prev=>{
                    return prev.filter(f => f.ques != k.ques)
                  })
                }}>X</span>
            </span>
            })
          }

        </div>
        {data.length > 0 ? (
          <div
            className="overflow-y-auto overflow-x-hidden flex-1"
          >
            <table className="table-auto  w-full text-center text-sm relative">
              <thead>
                <tr>
                  <th className="table-head" scope="col">
                    Id
                  </th>
                  {uniqueQues && uniqueQues?.title &&
                    <th className="table-head" scope="col">
                      {uniqueQues?.title}
                    </th>
                  }
                  <th className="table-head" scope="col">
                    Surveyor
                  </th>
                  <th className="table-head" scope="col">
                    Form
                  </th>
                  <th className="table-head" scope="col">
                    Edited On
                  </th>
                  <th className="table-head" scope="col">
                    Verified On
                  </th>
                  <th className="table-head" scope="col">
                    Verified By
                  </th>
                  <th className="table-head" scope="col">
                    Status
                  </th>
                  <th className="table-head" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {data.map((k) => {
                  return (
                    <TableRow
                      key={k.id}
                      ans={k}
                      uniqueQues={uniqueQues}
                      removeAns={() => removeAns(k.id)}
                      refAns={refAns}
                      user = {cookies?.user}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <span className="text-sm mt-4">No data found</span>
        )}
      </div>
      <AnsModal ref={refAns} />
    </div>
  );
};

const MemoTable = memo(Table);

export default MemoTable;
