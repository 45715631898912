import usePlan from "hooks/usePlan";
import { useLocation } from "react-router";
import GeoditLogo from "../GeoditLogo";
import NavUser from "./NavUser";
import Recharge from "./Recharge";

const Header = ({ miniSidebar, proj = null, showLoading, hideLoading }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const { plan } = usePlan();
  let color = "text-yellow-500";
  let msg = `Your plan expires in ${plan?.days_left} days`;
  if (plan?.days_left == 1) {
    msg = `Your plan expires in 1 day`;
  }
  if (plan?.days_left < 1) {
    color = "text-red-500";
    msg = `Your plan expires today`;
  }
  if (plan?.days_left < 0 || plan?.is_expired) {
    let delIn
    try {
      delIn = plan?.days_left + plan?.details?.backup
    } catch {
      delIn = 0
    }
    color = "text-red-500";
    if (delIn < 1) {
      msg = `Your plan expired on ${plan.expires_on} and data has been deleted.`;
    } else
      msg = `Your plan expired on ${plan.expires_on} and data will be deleted in ${delIn} days. Please recharge to avoid data deletion.`;
  }
  return (
    <nav
      className={`h-14 bg-white shadow-md flex justify-between fixed top-0 w-full z-100 ${pathname !== "/dashboard" ? (miniSidebar ? "pl-16" : "pl-52") : "pl-0"
        }`}
    >
      {pathname !== "/dashboard" && pathname !== "/" ? (
        proj?.name && (
          <div className="flex items-center text-sm">
            <span className="ml-3">{proj?.name}</span>
          </div>
        )
      ) : (
        <GeoditLogo />
      )}
      {(plan?.days_left < 15 || plan?.is_expired) && (
        <span className={`my-auto text-sm text-center ${color}`}>{msg}</span>
      )}
      <div className="flex justify-between items-center">
        <Recharge showLoading={showLoading} hideLoading={hideLoading} />
        <NavUser />
      </div>
    </nav>
  );
};

export default Header;
