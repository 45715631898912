import {
  useReducer,
  useEffect,
  createContext,
  useState,
} from "react";
import { PhoneInput } from "components/common/PhoneInput";
import { MaxChar, MaxValue, NumberValidator } from "components/common/Validators";
import { IoCaretBackSharp, IoCaretForwardSharp } from "react-icons/io5";
import { URL_TEAM_LIST, URL_TEAM_QUES_LIST, URL_TEAM_ADD_MEMBER, URL_TEAM_REMOVE_MEMBER, URL_TEAM_UPDATE_MEMBER } from "constants";
import useBase from "hooks/useBase";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import Card from "components/team/Card";
import { toast } from "react-toastify";
import { useModalDelete } from "hooks/useModalDelete";

export const TeamContext = createContext();

function reducerTeam(team, action) {
  switch (action.type) {
    case "fetchTeam":
      return action.data.data;
    case "addMember":
      return [action.data, ...team];
    case "removeMemLocally":
      let newTeam = [];
      team.forEach((v) => {
        if (v.id !== action.data) {
          newTeam.push(v);
        }
      });
      return newTeam;
    case "updateMemLocally":
      let newTeam2 = [];
      team.forEach((v) => {
        if (v.id === action.data.id) {
          newTeam2.push(action.data);
        } else {
          newTeam2.push(v);
        }
      });
      return newTeam2;
    case "addDataLocally":
      let newTeam3 = [];
      team.forEach((v) => {
        if (v.id === action.data.member_id) {
          let dataList = [...v.assign_data, action.data.data];
          newTeam3.push({ ...v, assign_data: dataList });
        } else {
          newTeam3.push(v);
        }
      });
      return newTeam3;
    case "removeDataLocally":
      let newTeam4 = [];
      team.forEach((v) => {
        if (v.id === action.data.member_id) {
          let dataList = [];
          if(action.data.id !== -1){
            v.assign_data.forEach((d) => {
            if (d.id !== action.data.id) {
              dataList.push(d)
            }
          })}
          newTeam4.push({ ...v, assign_data: dataList });
        } else {
          newTeam4.push(v);
        }
      });
      return newTeam4;
    default:
      return team;
  }
}
let timeout;

function Team() {
  const [team, dispatch] = useReducer(reducerTeam, []);
  const [quesList, setQuesList] = useState([{ label: "Select ...", value: null }])
  
  const [prev, setPrev] = useState(null);
  const [next, setNext] = useState(null);

  const [fieldError, setFieldError] = useState({ field: null, error: null });
  const { proj } = useBase()
  const axiosPrivate = useAxiosPrivate()
  const handleDelete = async (event, id) => {
    event.preventDefault();
    await axiosPrivate
      .post(`${URL_TEAM_REMOVE_MEMBER}/${proj?.id}`, {
        id: id
      })
      .then((res) => {
        dispatch({ type: "removeMemLocally", data: id });
      })
      .catch((error) => {
        let err = "Something went wrong. Please contact help desk"
        try {
          err = error.response.data.data
        } catch (e) {
        }
        toast.error(err, {
          toastId: "add-mem-err",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    dispatch({ type: "removeMemLocally", data: id });
  };
  const [DelModal, openDelModal] = useModalDelete(
    "Delete Surveyor",
    handleDelete,
    "All the unsaved data will be permanently deleted from the surveyor's device."
  );


  const handleChange = (id, type, value) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(async () => {
    await axiosPrivate
      .post(`${URL_TEAM_UPDATE_MEMBER}/${proj?.id}`, {
        id: id, [type]: value
      })
      .then((res) => {
        dispatch({ type: "updateMemLocally", data: res.data.data });
      })
      .catch((error) => { });
    },300)
  };

  useEffect(() => {
    fetcher()
    const fetchQues = async () => {
      await axiosPrivate
        .get(`${URL_TEAM_QUES_LIST}/${proj?.id}`)
        .then((res) => {
          const data = []
          res?.data?.data?.forEach((k) => {
            data.push({ value: k.id, label: `${k.page__form__name} - ${k.page__name} - ${k.question}` })
          })
          setQuesList(data)
        })
        .catch((error) => { });
    };
    fetchQues()
    document.title = "Geodit | Team";
  }, []);

  const handleAddMember = async (event) => {
    event.preventDefault();
    let cc = event.target.cc.value;
    let phone = event.target.phone.value;
    if (!phone || phone === "") {
      setFieldError({
        field: "phone",
        error: "Field cannot be empty",
      });
    } else {
      await axiosPrivate
        .post(`${URL_TEAM_ADD_MEMBER}/${proj?.id}`, {
          country_code: cc,
          phone_number: phone,
        })
        .then((res) => {
          dispatch({ type: "addMember", data: res.data.data });

        })
        .catch((error) => {
          let err = "Something went wrong. Please contact help desk"
          try {
            err = error.response.data.data
          } catch (e) {
          }
          toast.error(err, {
            toastId: "add-mem-err",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  const fetcher = async (params = {}, url=null) => {
    if(url)
    await axiosPrivate
    .get(url)
    .then((res) => {
      let data = res?.data;
      dispatch({ type: "fetchTeam", data: data });
      setPrev(data?.prev)
      setNext(data?.next)
    })
  .catch((error) => { });
  else
    await axiosPrivate
      .get(`${URL_TEAM_LIST}/${proj?.id}`, {
        params: params,
      })
      .then((res) => {
        let data = res?.data;
        dispatch({ type: "fetchTeam", data: data });
        setPrev(data?.prev)
        setNext(data?.next)

      })
      .catch((error) => { });
  };

  // should be atleaset admin
  if (proj?.access_level < 3)
    return (
      <div className="mb-8 mt-0 flex flex-col max-h-full">
        <div className="mb-4 lg:grid lg:grid-cols-3 gap-5 items-center justify-between">
          <form onSubmit={handleAddMember}>
            <div className="sm:flex gap-2">
              <div className="flex-1">
                <PhoneInput />
                {fieldError.field === "phone" && (
                  <div
                    className="invalid-tooltip"
                    id={`error-${fieldError.field}`}
                  >
                    {fieldError.error}
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="button bg-yellow-400 text-yellow-900 px-6 py-0 text-sm h-9 
                  mt-1 sm:mt-0 w-full sm:w-32"
              >
                Add
              </button>
            </div>
          </form>
          <div className="grid grid-cols justify-items-center my-4 lg:my-auto">
            <div className="flex my-auto text-sm gap-4">
                <span className={`my-auto mr-1 flex border px-4 py-1 pl-1 ${prev?"hover:bg-slate-200":"bg-gray-100 text-slate-400"} hover:cursor-pointer gap-2`} onClick={()=>{
                  if(prev)
                  fetcher({}, prev)
                }}>
                  <IoCaretBackSharp
                    className="my-auto  cursor-pointer text-lg"
                  />

                <span>Prev</span>
                </span>
                <span className={`my-auto mr-1 flex border px-4 py-1 pr-1 hover:cursor-pointer ${next?"hover:bg-slate-200":"bg-gray-100 text-slate-400"} gap-2`}  onClick={()=>{
                  if(next)
                  fetcher({}, next)
                }}>
                <span>Next</span>
                <IoCaretForwardSharp
                  className="my-auto cursor-pointer text-lg"
                />
                
                </span>

              </div>
          </div>
          <div className="my-auto ">
            <input
              className="input mb-0"
              type="text"
              name="name"
              id="name"
              placeholder="Search here"
              required
              onInput={(e) => {
                MaxChar(e, 20);
              }}
              onChange={(e) => {
                let value = e.target.value;
                if (timeout) {
                  clearTimeout(timeout);
                }
                timeout = setTimeout(() => {
                  fetcher({ q: value });
                }, 700);
              }}
            />
          </div>
        </div>
        {DelModal}
        <div className="mb-2">
        {team?.length > 0 ? team?.map((t) => {
          return <Card options={quesList} data={t} key={t.id} dispatch={dispatch} openDelModal={() => openDelModal(t.id)} handleChange={handleChange} />

        }) : <span className="w-100 text-center text-sm my-4">
          No member added yet.
        </span>}
        </div>
      </div>
    );
  else
    return (
      <span className="w-100 text-center text-sm">
        You are not authorized to access this page. Please ask project admin to
        change the privilage
      </span>
    );
}

export default Team;
