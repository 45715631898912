import { Listbox, Portal, Transition } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { IoMdArrowDropdown } from "react-icons/io";

const Dropdown = ({
  id,
  opt = [],
  def = null,
  handler = null,
  w = "w-48",
  mw = "min-w-[150px]",
  h = null,
  text = "text-sm",
  left = "0px",
  disabled = false,
  showText = true,
  showError = false,
}) => {
  const [value, setValue] = useState(def ? def : opt?.[0]);
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: "left",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 0],
        },
      },
    ],
  });
  useEffect(() => {
    setValue(def ? def : opt?.[0]);
    return () => { };
  }, [def]);

  return (
    <Listbox
      value={value}
      onChange={(e) => {
        setValue(e);
        if (handler) handler(e);
      }}
      disabled={disabled}
    >
      {id && (
        <input
          className="hidden"
          id={id}
          name={id}
          value={value.value}
          onChange={(e) => { }}
        />
      )}
      <div className={`relative ${w}`}>
        <Listbox.Button
          className={`input text-left ${showError && "border-red-500"}  ${disabled ? "bg-gray-100" : "bg-white"
            }  mb-0 ${h && h}`}
        >
          {showText && (
            <span className={`block truncate ${text} my-auto`}>
              {value?.label}
            </span>
          )}
          <span className="pointer-events-none absolute  my-auto inset-y-0 right-0 flex items-center pr-2">
            <IoMdArrowDropdown
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <div ref={setTargetElement} className={`${w}`}></div>

        <Portal>
          <div
            className="z-[100000]"
            ref={popperElRef}
            style={styles.popper}
            {...attributes.popper}
          >
            <Transition
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              beforeEnter={() => setPopperElement(popperElRef?.current)}
              afterLeave={() => setPopperElement(null)}
            >
              {/* <Portal> */}
              <Listbox.Options
                className={`left-[${left}] text-left focus:outline-none  ${mw} max-h-60 ${text} absolute top-0 overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm`}
                style={{ width: targetElement?.offsetWidth }}
              >
                {opt.map((o, idx) => {
                  if (o?.type == "br") {
                    return (
                      <div key={idx} className={`flex select-none w-100`}>
                        {o?.label && (
                          <span className="text-[10px] px-2 text-slate-400  my-auto">
                            {o?.label}
                          </span>
                        )}
                        <span className="flex-1 my-auto text-slate-400  border-t-[1px] border-t-slate-200"></span>
                      </div>
                    );
                  } else
                    return (
                      <Listbox.Option
                        key={idx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 px-4 ${active && "bg-yellow-100 text-yellow-900"
                          }`
                        }
                        value={o}
                      >
                        {({ selected }) => (
                          <span className={`${text} block truncate `}>
                            {o?.label}
                          </span>
                        )}
                      </Listbox.Option>
                    );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </Portal>
      </div>
    </Listbox>
  );
};

export default Dropdown;
