import { memo } from "react";

const InputTime = ({ ques, def, is_allowed, handler = null }) => {
  return (
    <input
      className={`input`}
      type="time"
      defaultValue={def}
      onBlur={(e) => {
        if (handler && is_allowed) handler(e.target.value);
      }}
      disabled={!is_allowed}
    />
  );
};

export default InputTime;

function propsAreEqual(prev, next) {
  return prev.ques === next.ques && prev.def === next.def;
}

export const MemoInputTime = memo(InputTime, propsAreEqual);
