import { createContext, useEffect, useState } from "react";
import { URL_CURRENT_PLAN } from "constants";
import useAuth from "hooks/useAuth";
import axios from "api/axios";
import useSWR from "swr";

const PlanContext = createContext({});

const fetcher = async (access) => {
    const response = await axios.get(URL_CURRENT_PLAN, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `JWT ${access}`,
        },
    });
    return response?.data?.data;
};

export const PlanProvider = ({ children }) => {
    const [plan, setPlan] = useState({});
    const { auth } = useAuth();

    const { data } = useSWR(auth?.access ? "current-plan" : null, () => fetcher(auth?.access));
    useEffect(() => {
        if (data)
            setPlan(data)
        else setPlan({})
    }, [data])
    return (
        <PlanContext.Provider value={{ plan }}>
            {children}
        </PlanContext.Provider>
    )
}

export default PlanContext;